import ErrorPage from '@pages/errors/Errors.page';
import Logger from '@src/api/Logger';
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError
} from 'react-router-dom';

interface IErrorInfo {
  data: string;
  error: { message?: string; stack?: string };
  status: number;
  internal: boolean;
  statusText: string;
}

const ErrorBoundary = () => {
  const navigate = useNavigate();
  const error = useRouteError();

  const fallBack = () => {
    navigate('/', { replace: true });
  };

  let errorInfo = {
    status: 'X0X',
    message: '알수 없는 오류!',
    info: '알수 없는 오류 입니다.',
    cacheClean: true,
    callback: fallBack
  };

  const errorObject = error as IErrorInfo;

  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 403:
        errorInfo = {
          status: '403',
          message: '권한 오류!',
          info: '접근 권한이 없습니다.',
          cacheClean: false,
          callback: fallBack
        };
        break;
      case 404:
        errorInfo = {
          status: '404',
          message: '존재하지 않는 페이지!',
          info: '해당 페이지가 없습니다.',
          cacheClean: false,
          callback: fallBack
        };
        break;
      default:
        break;
    }
  }

  let displayErrorMessage = `status : ${
    errorObject.status ? errorObject.status.toString() : '알수없는 애러'
  }, errorObjectMessage : ${
    errorObject.error ? errorObject.error.message : '알수없는 애러입니다'
  }`;
  Logger.error(displayErrorMessage, 'COMPONENT');

  return <ErrorPage {...errorInfo} />;
};

export default ErrorBoundary;
