import { useRef, useState } from 'react';
import { CommonStyle } from '@pages/modal/Common.style';
import { alertWrap } from '@hooks/modal/Modal.style';
import { IconModalClose } from '@resources/icon';
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { TPInputText } from '@units/input/TPInput.text';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import { Box, Button } from '@mui/material';
import { usePostFile } from '@hooks/file/postFile';
import _ from 'lodash';
import { TPInputCheckBox } from '@src/units/input/TPInput.checkBox';
import { useLocation, useParams } from 'react-router-dom';

const FileUploadPage = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { fileId } = useParams();

  const { uploadHandle, reUploadFile, closeModal } = usePostFile();

  const [columnOption, setColumnOption] = useState('TAB');
  const [rowOption, setRowOption] = useState('WINDOWS');
  const [selectedFile, setSelectedFile] = useState<string | Blob>('');

  const handleFileChange = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('taskFile', selectedFile);
    if (location.pathname.includes('reUpload')) {
      reUploadFile({ fileId, rowOption, columnOption, formData });
    } else {
      uploadHandle({ fileId, rowOption, columnOption, formData });
    }
  };

  return (
    <div css={[CommonStyle, alertWrap]}>
      <button className="alertClose" onClick={closeModal}>
        <IconModalClose />
      </button>
      <div>
        <TPHeadlineBasic decoration type="h2">
          파일 등록
        </TPHeadlineBasic>
      </div>
      <div
        ref={scrollRef}
        className="modal--div--box"
        style={{ paddingBottom: '24px' }}
      >
        <div className={'input--text--wrap'}>
          <TPInputText
            label={'파일'}
            name={'fileName'}
            value={(selectedFile as File)?.name ?? ''}
            changeText={handleFileChange}
            required={true}
            placeholder={'파일을 등록해주세요.'}
            disabled={true}
          />

          <Button
            variant="contained"
            component="label"
            style={{
              width: '180px',
              height: '52px',
              backgroundColor: '#9B51E0',
              borderRadius: '50px'
            }}
          >
            Upload
            <input
              hidden
              accept=".xls, .xlsx, .txt"
              multiple
              type="file"
              onChange={handleFileChange}
            />
          </Button>

          {(selectedFile as File)?.type?.includes('text') ? (
            <FileImportOption
              nextStep={() => {}}
              columnOption={columnOption}
              rowOption={rowOption}
              setColumnOption={setColumnOption}
              setRowOption={setRowOption}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <Box display={'flex'} justifyContent={'space-between'} gap={2} mt={3}>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="outlined"
            label="취소하기"
            fullWidth
            onClick={closeModal}
            size={'large'}
          />
        </Box>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="contained"
            label="등록하기"
            fullWidth
            disabled={!selectedFile}
            onClick={handleUpload}
            size={'large'}
          />
        </Box>
      </Box>
    </div>
  );
};

const FileImportOption = ({
  nextStep,
  columnOption,
  rowOption,
  setColumnOption,
  setRowOption
}: {
  nextStep: () => void;
  columnOption: string;
  rowOption: string;
  setColumnOption: (columnOption: string) => void;
  setRowOption: (rowOption: string) => void;
}) => {
  return (
    <div>
      <Box pb={4} pt={4}>
        <div className="title">행 구분자 선택</div>
        <div className={'input--checkbox--wrap'}>
          <TPInputCheckBox
            option={'Tab'}
            optionKey={'TAB'}
            selectOptions={[columnOption]}
            onClick={setColumnOption}
          />
          <TPInputCheckBox
            option={'Comma'}
            optionKey={'COMMA'}
            selectOptions={[columnOption]}
            onClick={setColumnOption}
          />
        </div>
      </Box>
      <Box>
        <div className="title">열 구분자 선택</div>
        <div className={'input--checkbox--wrap'}>
          <TPInputCheckBox
            option={'Windows(\\r\\n)'}
            optionKey={'WINDOWS'}
            selectOptions={[rowOption]}
            onClick={setRowOption}
          />
          <TPInputCheckBox
            option={'Unix/Mac(\\n)'}
            optionKey={'MAC'}
            selectOptions={[rowOption]}
            onClick={setRowOption}
          />
        </div>
      </Box>
    </div>
  );
};

export default FileUploadPage;
