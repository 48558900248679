import styled from '@emotion/styled';
import { SortButton } from '@src/units/button/SortButton';

interface IColumnHeadCompProps {
  title: any;
  field: string;
  isSort?: { code: string; isAsc: boolean };
  setIsSort?: any;
  sortType?: any;
}

export default function ColumnHeadComp(props: IColumnHeadCompProps) {
  const { isSort, setIsSort, title, field, sortType } = props;

  if (sortType === 'unable') return <Wrap>{title}</Wrap>;

  return (
    <Wrap>
      <SortButton
        show={isSort?.code === field}
        onChange={(v: any) => {
          if (v) {
            setIsSort({ code: field, isAsc: v === 'up' });
          } else {
            setIsSort({ code: '', isAsc: false });
          }
        }}
      >
        <span
          className="title"
          {...(typeof title === 'string'
            ? {
                dangerouslySetInnerHTML: { __html: title }
              }
            : {
                children: title
              })}
        />
      </SortButton>
    </Wrap>
  );
}

const Wrap = styled.div`
  font-family: 'IBM Plex Sans', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  margin: auto;
  white-space: nowrap;

  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: center;
    color: #242424;
  }
  .count {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #242424;
    margin-left: 8px;
  }
`;
