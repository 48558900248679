import api from '@api/Api';
import { useMutation } from '@tanstack/react-query';

/**
 * /projectManage/projectKeyValid
 * @description 언어페어 리더, 작업자 전체 조회
 * @returns {keyValidData: any,
 *     validKeyError: unknown,
 *     keyValid: function,
 *     validKey: boolean}
 */
export const useGetProjectKeyValid = () => {
  //#region api call
  const getData = async (key: string) => {
    return await api
      .get('/projectManage/projectKeyValid', { projectKey: key })
      .then((res) => res);
  };
  //#endregion

  //#region useQuery define
  const {
    data: keyValidData,
    error: validKeyError,
    mutate: keyValid,
    isError: validKey
  } = useMutation({
    mutationFn: getData
  });
  //#endregion

  return {
    keyValidData,
    validKeyError,
    keyValid,
    validKey
  };
};
