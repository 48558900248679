import React, { useState } from 'react';
import { IconDetail } from '@src/resources/icon';
import { TPButtonAction } from '@src/units/button/TPButton.Action';
import { TPPopoverMenu } from '@src/units/popover/TPPopover.Menu';
import { format } from 'date-fns';
import ColumnHeadComp from '@src/pages/component/ColumnHead.Comp';
import { getLanguage } from '@common/Code';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import _ from 'lodash';
import { useNavigate as Navigate } from 'react-router-dom';
import { ILanguageTask } from '@src/common/interfaces';
import { Button } from '@mui/material';
import TPLoadingButton from '@src/units/button/TPLoadingButton';
import { usePostFileUpload as postFileUpload } from '@src/hooks/languageTask/postFileUpload';

export const languageTaskColumns = (isSort: any, setIsSort: any) =>
  [
    {
      code: 'languageTaskId',
      value: '작업번호',
      width: 120,
      renderHeader(headerData: any, rowData: any, ...s: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'key',
      value: '관리키',
      width: 300,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'companyName',
      value: '고객사',
      width: 300,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'projectTitle',
      value: '프로젝트명',
      width: 300,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'taskTitle',
      value: '작업명',
      width: 300,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'startLanguage',
      value: '출발어',
      width: 300,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'destinationLanguage',
      value: '도착어',
      width: 300,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'isUpload',
      value: '업로드',
      width: 300,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData(isUpload: boolean, rowData: ILanguageTask) {
        const navigate = Navigate();
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              navigate(
                `/languageTask/${isUpload ? 'reUpload' : 'upload'}/${
                  rowData?.languageTaskId
                }`,
                {
                  state: rowData
                }
              );
            }}
          >
            {isUpload && '다시'} 업로드
          </Button>
        );
      }
    },
    {
      code: 'isTaskCreated',
      value: '일감생성',
      width: 300,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData(isTaskCreated: boolean, rowData: ILanguageTask) {
        const navigate = Navigate();
        return (
          <Button
            disabled={!rowData.isUpload && !isTaskCreated}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              navigate(`/languagetask/create/${rowData?.languageTaskId}`);
            }}
          >
            일감생성
          </Button>
        );
      }
    }
  ] as const;
