import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useGetProjectCompanyList } from '@hooks/project/getProjectCompanyList';
import { useGetProjectTitleValid } from '@hooks/project/getProjectTitleValid';
import { useGetProjectKeyValid } from '@hooks/project/getProjectKeyValid';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@hooks/modal/useToast';
import { useAlert } from '@hooks/modal/useAlert';
import _ from 'lodash';
import { usePageFunc } from '@hooks/utils';

/**
 * @description 신규 프로젝트 생성 /projectManage
 * @returns { convertCompanyList: ICompanyListOption[],
 *     projectTitle: string,
 *     setProjectTitle: function,
 *     validTitleFunc: function,
 *     projectKey: string,
 *     changeKey: function,
 *     validKeyFunc: function,
 *     companyId: string,
 *     changeCompanyId: function,
 *     contractType: string,
 *     setContractType: function,
 *     dateFilter: { startDateTime: string; endDateTime: string },
 *     setDateFilter: function,
 *     validInfo: { projectTitle: string, projectKey: string, companyId: string, dateFilter: string },
 *     clickCreate: function,
 *     closeModal: function}
 */

export const machineTranslationTypeList = [
  { value: 'NMT_2B', label: 'NMT_2B', option: 'NMT_2B' },
  { value: 'GPT_35', label: 'GPT-3.5', option: 'GPT_35' },
  { value: 'GPT_35IN', label: 'GPT-3.5-IN', option: 'GPT_35IN' },
  { value: 'GPT_40', label: 'GPT-4.0', option: 'GPT_40' },
  { value: 'GPT_40T', label: 'GPT-4.0T', option: 'GPT_40T' }
] as const;

export const usePostProject = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openHandle: alertHandle } = useAlert();
  const { openHandle } = useToast();
  const { getDateTime } = usePageFunc();
  const { companyList } = useGetProjectCompanyList();
  const [convertCompanyList, setConvertCompanyList] = useState<
    {
      value?: string;
      label: string;
      option: string;
    }[]
  >([]);
  const { titleValidData, titleValid, validTitle, validTitleError } =
    useGetProjectTitleValid();
  const { keyValidData, keyValid, validKey, validKeyError } =
    useGetProjectKeyValid();

  const [machineTranslationType, setMachineTranslationType] = useState(
    machineTranslationTypeList[0].value
  );
  const [prompt, setPrompt] = useState('');
  const [projectTitle, setProjectTitle] = useState('');
  const [projectKey, setProjectKey] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [contractType, setContractType] = useState('CASE');
  const [dateFilter, setDateFilter] = useState<{
    startDateTime: string;
    endDateTime: string;
  }>({
    startDateTime: '',
    endDateTime: ''
  });
  const [validInfo, setValidInfo] = useState({
    projectTitle: '',
    projectKey: '',
    companyId: '',
    dateFilter: ''
  });

  const convertCompanyListFunc = () => {
    const convert = _.map(companyList?.companyList, (item) => {
      return { value: '', label: item.companyName, option: item.companyId };
    });
    setConvertCompanyList(convert);
  };

  const changeCompanyId = (companyId: string) => {
    setCompanyId(companyId);
  };

  const changeProjectTitle = (title: string) => {
    setValidInfo({ ...validInfo, projectTitle: '' });
    setProjectTitle(title);
  };

  const changeKey = (key: string) => {
    setValidInfo({ ...validInfo, projectKey: '' });
    const regex = /^[A-Z0-9]{0,5}$/;
    if (!regex.test(key)) return;
    setProjectKey(key);
  };

  const validTitleFunc = () => {
    titleValid(projectTitle);
  };

  const validKeyFunc = () => {
    keyValid(projectKey);
  };

  const clickCreate = () => {
    alertHandle({
      title: '등록 확인',
      text: '입력한 내용으로 등록하시겠습니까?',
      cancelLabel: '취소',
      ok: () => {
        createProject();
        navigate('/project', { replace: true });
      }
    });
  };

  const closeModal = () => {
    alertHandle({
      title: '작성 취소',
      text: '입력 중인 내용이 모두 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        navigate('/project');
      }
    });
  };

  //#region api call
  const getData = async () => {
    const { startDateTime, endDateTime } = dateFilter;
    return await api
      .post('/projectManage', {
        machineTranslationType,
        prompt,
        projectTitle,
        projectKey,
        companyId,
        contractType,
        startDateTime: getDateTime({
          date: new Date(startDateTime),
          filter: 'start'
        }),
        endDateTime: getDateTime({
          date: new Date(endDateTime),
          filter: 'end'
        })
      })
      .then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { mutate: createProject } = useMutation({
    mutationFn: getData,
    onSuccess: () => {
      navigate('/project', { replace: true });
      openHandle({
        text: '신규 프로젝트가 등록되었습니다.',
        severity: 'success'
      });
      queryClient.invalidateQueries(['/projectManage/allList']);
    },
    onError: () => {
      openHandle({
        text: `신규 프로젝트 등록에 실패하였습니다.`,
        severity: 'error'
      });
    }
  });
  //#endregion

  useEffect(() => {
    convertCompanyListFunc();
    return () => {};
  }, [companyList]);

  useEffect(() => {
    if (validTitle) {
      const err = validTitleError as any;
      if (err.status === 400) {
        setValidInfo({
          ...validInfo,
          projectTitle: 'invalid'
        });
      } else {
        setValidInfo({
          ...validInfo,
          projectTitle: 'invalid:regex'
        });
      }
    } else {
      if (titleValidData?.status === 200) {
        setValidInfo({
          ...validInfo,
          projectTitle: 'valid'
        });
      }
    }
    return () => {};
  }, [titleValidData, validTitle]);

  useEffect(() => {
    if (validKey) {
      const err = validKeyError as any;
      if (err.status === 400) {
        setValidInfo({
          ...validInfo,
          projectKey: 'invalid'
        });
      } else {
        setValidInfo({
          ...validInfo,
          projectKey: 'invalid:regex'
        });
      }
    } else {
      if (keyValidData?.status === 200) {
        setValidInfo({
          ...validInfo,
          projectKey: 'valid'
        });
      }
    }
    return () => {};
  }, [keyValidData, validKey]);

  useEffect(() => {
    if (companyId) {
      setValidInfo({
        ...validInfo,
        companyId: 'valid'
      });
    } else {
      setValidInfo({
        ...validInfo,
        companyId: 'invalid'
      });
    }
    return () => {};
  }, [companyId]);

  useEffect(() => {
    if (dateFilter.startDateTime && dateFilter.endDateTime) {
      setValidInfo({
        ...validInfo,
        dateFilter: 'valid'
      });
    } else {
      setValidInfo({
        ...validInfo,
        dateFilter: 'invalid'
      });
    }
    return () => {};
  }, [dateFilter]);

  useEffect(() => {
    setValidInfo({
      ...validInfo
    });
    return () => {};
  }, []);

  return {
    machineTranslationTypeList,
    machineTranslationType,
    setMachineTranslationType,
    prompt,
    setPrompt,
    convertCompanyList,
    projectTitle,
    changeProjectTitle,
    validTitleFunc,
    projectKey,
    changeKey,
    validKeyFunc,
    companyId,
    changeCompanyId,
    contractType,
    setContractType,
    dateFilter,
    setDateFilter,
    validInfo,
    clickCreate,
    closeModal
  };
};
