//lib
import { Box } from '@mui/material';
import { useNavigate, Outlet } from 'react-router-dom';
import _ from 'lodash';
//etc
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { IconCalendar, IconRefresh } from '@resources/icon';
import { ProjectWrap } from './Project.style';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import { IconBtnRocket } from '@resources/icon';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import { projectColumns } from '@pages/project/Project.columns';
import { TPInputSearchNew } from '@src/units/input/TPInput.searchNew';
import DataTable from '@src/pages/component/DataTable';

//hooks
import { usePostProjectList } from '@hooks/project/postList';
import { TPSelectCheckManager } from '@units/select/TPSelect.checkPM';
import { format } from 'date-fns';
import { TPButtonActionCalendar } from '@units/button/TPButton.ActionCalendar';
import { useRangeCalendar } from '@hooks/modal/useRangeCalendar';

/**
 * 프로젝트관리 페이지
 * route : /account
 * @returns
 */
function ProjectPage() {
  const navigate = useNavigate();

  const {
    filterOption,
    changeSearchType,
    setChangeSearchType,
    changeKeyword,
    setChangeKeyword,
    updateKeyword,
    projectStatus,
    changeProjectStatus,
    projectManagerUserIdList,
    changeProjectManagerUserIdList,
    contractType,
    setContractType,
    exposureStatus,
    setExposureStatus,
    dateType,
    setDateType,
    dateFilter,
    setDateFilter,
    projectList,
    setIsSort,
    isSort,
    clear,
    clearFlag
  } = usePostProjectList();
  const { totalProjectManagerList } = filterOption;
  const { openHandle, Component: Calendar } = useRangeCalendar();
  //#region store function

  const categoryOptions = [
    { code: '', label: '전체' },
    { code: 'COMPANY_NAME', label: '고객사' },
    { code: 'TITLE', label: '프로젝트명' },
    { code: 'PROJECT_KEY', label: '프로젝트키' }
  ];

  return (
    <section css={ProjectWrap}>
      <Box mb={'26px'} display={'flex'}>
        <Box display={'flex'} flexDirection={'column'} flexGrow={1} ml={2}>
          <dl className="project--dl--info">
            <TPHeadlineBasic type="h2" textAlign="left">
              보라캣 프로젝트 관리
            </TPHeadlineBasic>
          </dl>
        </Box>
      </Box>

      <Box mb={3} display={'flex'} justifyContent={'space-between'}>
        <Box display={'inline-flex'} gap={'8px'}>
          <TPSelectCheck
            label="카테고리"
            useOptionLabel
            optionList={categoryOptions}
            selectOptions={[changeSearchType]}
            changeSelect={setChangeSearchType}
          />
          {changeSearchType[0] ? (
            <TPInputSearchNew
              value={changeKeyword}
              placeholder={`${
                _.find(categoryOptions, { code: changeSearchType })?.label
              } 검색`}
              onChange={(e) => setChangeKeyword(e.target.value)}
              clickSearch={updateKeyword}
            />
          ) : null}
          <TPSelectCheck
            label="상태"
            useOptionLabel
            optionList={[
              { code: '', label: '전체' },
              { code: 'NEW', label: '신규' },
              { code: 'PROGRESS', label: '진행' },
              { code: 'END', label: '종료' }
            ]}
            selectOptions={projectStatus}
            changeSelect={changeProjectStatus}
          />
          <TPSelectCheckManager
            label="담당PM"
            PMList={totalProjectManagerList}
            showAvatar={true}
            selectOptions={projectManagerUserIdList}
            changeSelect={changeProjectManagerUserIdList}
          />
          <TPSelectCheck
            label="견적 유형"
            useOptionLabel
            optionList={[
              { code: '', label: '전체' },
              { code: 'MONTHLY', label: '월별 견적' },
              { code: 'CASE', label: '건별 견적' }
            ]}
            selectOptions={[contractType]}
            changeSelect={setContractType}
          />

          <TPSelectCheck
            label="노출여부"
            useOptionLabel
            optionList={[
              { code: '', label: '전체' },
              { code: 'EXPOSURE', label: '노출' },
              { code: 'NON_EXPOSURE', label: '미노출' }
            ]}
            selectOptions={[exposureStatus]}
            changeSelect={setExposureStatus}
          />
          <TPSelectCheck
            label="계약기간"
            useOptionLabel
            optionList={[
              { code: '', label: '전체' },
              { code: 'CONTRACT_START_DATE', label: '시작일' },
              { code: 'CONTRACT_END_DATE', label: '종료일' }
            ]}
            selectOptions={[dateType]}
            changeSelect={setDateType}
          />

          {dateType && (
            <>
              <div
                onClick={() => {
                  openHandle('project', dateFilter, setDateFilter);
                }}
              >
                <TPButtonActionCalendar
                  outline={true}
                  size={'medium'}
                  content={
                    dateFilter.startDateTime && dateFilter.endDateTime
                      ? `${format(
                          new Date(dateFilter.startDateTime),
                          'yyyy.MM.dd'
                        )} - ${format(
                          new Date(dateFilter.endDateTime),
                          'yyyy.MM.dd'
                        )}`
                      : ''
                  }
                  icon={<IconCalendar />}
                />
              </div>
              <Calendar />
            </>
          )}

          {clearFlag ? null : (
            <button className="main--button--refresh" onClick={clear}>
              <IconRefresh />
              <span>초기화</span>
            </button>
          )}
        </Box>

        <TPButtonBasic
          className=""
          label="프로젝트 등록"
          onClick={() => {
            navigate('/project/register');
          }}
          size="medium"
          startIcon={<IconBtnRocket />}
          variant="contained"
        />
      </Box>

      <Box>
        <DataTable
          columns={projectColumns(isSort, setIsSort)}
          dataList={projectList}
          clickRowData={() => {}}
          noRowsMessage={'등록된 프로젝트가 없습니다.'}
        />
      </Box>

      <Outlet />
    </section>
  );
}

export default ProjectPage;
