import api from '@api/Api';
import { useMutation } from '@tanstack/react-query';

/**
 * /slack/userProfile
 * @description slack 유저 프로필 조회
 * @returns {data: any, slackIdValid: function, slackIdError: boolean}
 */
export const useGetSlackUserProfile = () => {
  //#region api call
  const getData = async (userToken: string) => {
    return await api
      .get('/slack/userProfile', { userToken })
      .then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const {
    data,
    mutate: slackIdValid,
    error: slackIdError
  } = useMutation({
    mutationFn: getData
  });
  //#endregion

  return {
    data,
    slackIdValid,
    slackIdError
  };
};
