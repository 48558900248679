import { createTheme } from '@mui/material/styles/';

export const TPTooltipTheme = createTheme({
  typography: { fontFamily: 'Spoqa Han Sans Neo', fontSize: 16 },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#2C3558',
          color: '#fff',
          fontWeight: 400,
          maxWidth: 220,
          fontSize: '0.75rem',
          border: '0 none',
          borderRadius: '8px 0'
        },
        arrow: {
          '&::before': {
            backgroundColor: '#2C3558'
          }
        },
        popper: {
          '&.tooltip--description .MuiTooltip-tooltip': {
            background: '#FFFFFF',
            border: '1px solid #D5D3E4',
            boxSizing: 'border-box',
            boxShadow: '0px 1px 6px 2px rgba(240, 237, 246, 0.8)',
            borderRadius: '12px',
            color: '#2C3558',
            lineHeight: '160%',
            padding: '16px'
          },
          '&.tooltip--description.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltip, &.tooltip--description.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip':
            { marginTop: 0, marginBottom: 0 },
          '& .MuiBadge-root': {
            lineHeight: 1,
            verticalAlign: 'baseline',
            marginRight: '5px'
          },
          '& .MuiBadge-badge': {
            marginLeft: '2px'
          }
        }
      }
    }
  }
});

export const TPTooltipListViewTheme = createTheme({
  typography: { fontFamily: 'Spoqa Han Sans Neo', fontSize: 16 },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#fff',
          color: '#fff',
          fontWeight: 400,
          maxWidth: 220,
          fontSize: '0.75rem',
          border: '1px solid #D5D3E4',
          borderRadius: '12px',
          padding: '16px 0'
        },
        arrow: {
          display: 'none'
        },
        popper: {
          '&.tooltip--description .MuiTooltip-tooltip': {
            background: '#FFFFFF',
            border: '1px solid #D5D3E4',
            boxSizing: 'border-box',
            boxShadow: '0px 1px 6px 2px rgba(240, 237, 246, 0.8)',
            borderRadius: '12px',
            color: '#2C3558',
            lineHeight: '160%'
          },
          '&.tooltip--description.MuiTooltip-popper[data-popper-placement*="top"] .MuiTooltip-tooltip, &.tooltip--description.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip':
            { marginTop: 0, marginBottom: 0 },
          '& .MuiBadge-root': {
            lineHeight: 1,
            verticalAlign: 'baseline',
            marginRight: '5px'
          },
          '& .MuiBadge-badge': {
            marginLeft: '2px'
          }
        }
      }
    }
  }
});
