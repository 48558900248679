/**
 * @description 사용언어 공통 코드
 */
export const LANGUAGE = [
  { code: '', label: '' },
  { code: 'ALL', label: '전체' },
  { code: 'KO', label: '한국어' },
  { code: 'EN', label: '영어' },
  { code: 'ZH_CN', label: '중국어(간체)' },
  { code: 'ZH_TW', label: '중국어(번체)' },
  { code: 'JA', label: '일본어' },
  { code: 'DE', label: '독일어' },
  { code: 'FR', label: '프랑스어' },
  { code: 'ES', label: '스페인어(유럽)' },
  { code: 'ES_419', label: '스페인어(남미)' },
  { code: 'TR', label: '터키어' },
  { code: 'ID', label: '인도네시아어' },
  { code: 'TH', label: '태국어' },
  { code: 'RU', label: '러시아어' },
  { code: 'PT', label: '포르투갈어(유럽)' },
  { code: 'PT_BR', label: '포르투갈어(브라질)' },
  { code: 'VI', label: '베트남어' },
  { code: 'PL', label: '폴란드어' },
  { code: 'IT', label: '이탈리아어' },
  { code: 'NL', label: '네덜란드어' },
  { code: 'NO', label: '노르웨이어' },
  { code: 'AR', label: '아랍어' },
  { code: 'HI', label: '힌디어' },
  { code: 'MS', label: '말레이어' },
  { code: 'CS', label: '체코어' },
  { code: 'TL', label: '필리핀어' },
  { code: 'SV', label: '스웨덴어' },
  { code: 'DA', label: '덴마크어' },
  { code: 'FI', label: '핀란드어' },
  { code: 'HU', label: '헝가리어' },
  { code: 'HR', label: '크로아티아어' },
  { code: 'SR', label: '세르비아어' },
  { code: 'ETC', label: '기타' }
] as const;
export type LanguageCodeType = (typeof LANGUAGE)[number]['code'];
export type LanguagePairType = {
  startLanguage: LanguageCodeType;
  destinationLanguage: LanguageCodeType;
};
/**
 * @description 사용언어 코드에 따른 라벨 반환
 * @param LanguageCodeType
 * @returns LanguageCodeType[],
 */
export const getLanguage = (LanguageCodeType: LanguageCodeType[]) => {
  return LANGUAGE.filter((el) => LanguageCodeType.includes(el.code));
};

/**
 * @description 프로젝트상태 공통 코드
 */
export const STATUS = [
  { code: 'ALL', label: '전체', text: '전체' },
  { code: 'NEW', label: '신규', text: '신규 작업' },
  { code: 'COMPLETE_ASSIGN', label: '배정', text: '작업 배정' },
  { code: 'PROGRESS', label: '진행', text: '작업 진행' },
  { code: 'STOP', label: '중지', text: '작업 중지' },
  { code: 'COMPLETE', label: '완료', text: '작업 완료' },
  { code: 'REJECT', label: '반려', text: '작업 반려' },
  { code: 'COMPLETE_TASK', label: '작업완료', text: '작업완료' },
  { code: 'DELIVERY_COMPLETE', label: '납품완료', text: '납품완료' },
  { code: 'END', label: '종료', text: '작업종료' },
  { code: 'WAIT_DELIVERY', label: '납품대기', text: '납품대기' },
  { code: 'REQUEST_QUOTE', label: '견적발송', text: '견적발송' },
  { code: 'QUOTE_DELIVERY', label: '견적발송', text: '견적발송' }
] as const;
export type StatusCodeType = (typeof STATUS)[number]['code'];
/**
 * @description 프로젝트상태 코드에 따른 라벨 반환
 * @param StatusCodeType[]
 * @returns StatusCodeType[]
 */
export const getStatus = (getStatus: StatusCodeType[]) => {
  return STATUS.filter((el) => getStatus.includes(el.code));
};

/**
 * @description 계약정보 공통 코드
 */
export const CONTRACT_TYPE = [
  { code: 'CASE', label: '건별 발송', text: '건별 발송' },
  { code: 'MONTHLY', label: '월별 발송', text: '월별 발송' }
] as const;
export type ContractTypeCode = (typeof CONTRACT_TYPE)[number]['code'];
export const getContractType = (getContractType: ContractTypeCode[]) => {
  return CONTRACT_TYPE.filter((el) => getContractType.includes(el.code));
};
