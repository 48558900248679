import { useNavigate } from 'react-router-dom';
import { errorsWrap } from '@pages/errors/Errors.style';
import Error404 from '@images/error-404.svg';

/**
 * @description 에러 페이지
 * @param {string} status
 * @param {string} message
 * @param {string} info
 * @param {boolean} cacheClean
 * @param {() => void} callback
 * @return {JSX.Element}
 * @constructor
 */
function ErrorsPage({
  status,
  message,
  info,
  cacheClean,
  callback
}: {
  status?: string;
  message?: string;
  info?: string;
  cacheClean?: boolean;
  callback?: () => void;
}) {
  const navigate = useNavigate();

  const fallback = () => {
    if (callback) {
      callback();
    } else {
      if (cacheClean) {
        window.localStorage.clear();
      }
      navigate('/login', { replace: true });
    }
  };

  return (
    <section css={errorsWrap}>
      <div className="errorsInner">
        <em className="errorsStatusWrap">
          {status === '404' ? (
            <img src={Error404} alt={'404'} />
          ) : (
            status?.split('').map((item, index) => {
              return (
                <span key={index} className={'errorsStatus'}>
                  {item}
                </span>
              );
            })
          )}
        </em>
        <strong className="errorsMessage">{message}</strong>
        <p className={`errorsInfo ${status !== '404' ? 'error' : ''}`}>
          {info}
        </p>
        <button className="errorsHomeBtn" onClick={fallback}>
          {cacheClean ? '로그인 화면으로 가기' : '홈으로 가기'}
        </button>
      </div>
    </section>
  );
}

export default ErrorsPage;
