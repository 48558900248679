import { createTheme } from '@mui/material/styles/';
import styled from '@emotion/styled';

// input을 감싸는 div 엘리먼트 스타일
export const TPInputWrap = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: top;
  /* margin-right: 8px; */
`;

// textarea 감싸는 div 엘리먼트 스타일
export const TPTextAreaWrap = styled.div`
  textarea {
    width: 100%;
    color: #2c3558;
    border: 1px solid #d5d3e4;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 16.5px 14px;
    resize: none;
    outline: none;
    :hover {
      border-color: #aa51e0;
    }
    :focus {
      border: 1px solid #aa51e0;
    }
  }
`;

// check box optoin style
export const TPInputCheckBoxWrap = styled.div`
  /* margin-right: 10px; */
  width: 100%;
  height: 42px;
  padding: 0 24px 0 8px;
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
    background-color: #f9f5fd;
  }
  &.disabled {
    filter: grayscale(100%);
    opacity: 0.3;
  }
`;

// select optoin style
export const TPInputSelectWrap = styled.div`
  /* margin-right: 10px; */
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  :hover {
    cursor: pointer;
    background-color: #f9f5fd;
  }
`;
// input date 테마
export const TPInputDateTheme = createTheme({
  typography: { fontFamily: 'Spoqa Han Sans Neo', fontSize: 16 },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: { display: 'flex' }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 500,
          color: '#2C3558',
          '&.Mui-focused': {
            color: '#2C3558'
          },
          '& sup': { marginLeft: 4, top: 0, fontSize: '1rem' }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#FFFFFF',
          boxSizing: 'border-box',
          fontSize: '1rem',
          height: 56,
          color: '#2C3558',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#AA51E0'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #AA51E0'
          }
        },
        notchedOutline: {
          border: '1px solid #D5D3E4',
          boxSizing: 'border-box',
          borderRadius: '12px'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: { fontSize: '0.75rem', color: '#E6311A' }
      }
    }
  }
});

// input text 테마
export const TPInputTextTheme = createTheme({
  typography: { fontFamily: 'Spoqa Han Sans Neo', fontSize: 16 },
  components: {
    MuiFormControl: {
      variants: [
        {
          props: { size: 'small' },
          style: { height: 48 }
        },
        {
          props: { size: 'medium' },
          style: { height: 56 }
        }
      ],
      styleOverrides: {
        root: {
          '&.input--amount input': {
            width: '90%'
          },
          '&.input--title input': {
            fontSize: '2rem'
          },
          '&.input--title .MuiOutlinedInput-root': {
            height: '64px'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 500,
          color: '#2C3558',
          '&.Mui-focused': {
            color: '#2C3558'
          },
          '& sup': { marginLeft: 4, top: 0, fontSize: '1rem' }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: '#fff',
          boxSizing: 'border-box',
          fontSize: '1rem',
          borderRadius: '12px',
          color: '#2C3558',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#AA51E0'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #AA51E0'
          }
        },
        notchedOutline: {
          border: '1px solid #D5D3E4',
          boxSizing: 'border-box',
          borderRadius: '12px'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: { fontSize: '0.75rem', color: '#E6311A' }
      }
    }
  }
});

// 라디오 박스 테마
export const TPInputRadioTheme = createTheme({
  typography: { fontFamily: 'Spoqa Han Sans Neo', fontSize: 16 },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 500,
          color: '#2C3558',
          '&.Mui-focused': {
            color: '#2C3558'
          },
          '& sup': { marginLeft: 4, top: 0, fontSize: '1rem' }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 400,
          color: '#2C3558'
        }
      }
    }
  }
});

//체크 박스 테마
export const TPInputCheckBoxTheme = createTheme({
  typography: { fontFamily: 'Spoqa Han Sans Neo', fontSize: 16 },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 500,
          color: '#2C3558',
          '&.Mui-focused': {
            color: '#2C3558'
          },
          '& sup': { marginLeft: 4, top: 0, fontSize: '1rem' }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 400,
          color: '#2C3558'
        }
      }
    }
  }
});

// 검색창 테마
export const TPInputSearchTheme = createTheme({
  typography: { fontFamily: 'Spoqa Han Sans Neo', fontSize: 14 },
  components: {
    MuiOutlinedInput: {
      variants: [
        {
          props: { size: 'small' },
          style: { width: 160 }
        },
        {
          props: { size: 'medium' },
          style: { width: 320 }
        }
      ],
      styleOverrides: {
        root: {
          height: 48,
          background: '#fff',
          verticalAlign: 'top',
          borderRadius: 12,
          fontSize: '0.875rem',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#AA51E0'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #AA51E0'
          }
        },
        input: { paddingRight: 32 },
        notchedOutline: {
          borderRadius: 12,
          paddingRight: 45
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          position: 'absolute',
          display: 'inline-block',
          right: 3,
          top: 3,
          minWidth: 0,
          padding: '8px 9px 2px',
          borderRadius: '50%',
          '& svg': { width: 24, height: 24 }
        }
      }
    }
  }
});
