import _ from 'lodash';

/**
 * @description hooks에서 공통으로 사용하는 함수
 * @returns {{arraySet,defaultUserList}}
 */
export const useHookFunc = () => {
  const arraySet = (arr: string[], e: string) =>
    arr.includes(e) ? _.filter(arr, (item) => item !== e) : [...arr, e];

  //todo 함수는 아니지만 일단 여기 모아둔다...
  const defaultUserList = [{ name: '전체', userId: 0, avatarImgUrl: '' }];

  return { arraySet, defaultUserList };
};

/**
 * @description 페이지에서 공통으로 사용하는 함수
 * @returns {{
 *    formattedString,getStatus,
 *    taskAmountText,taskAmountRate,workerType
 * }}
 */
export const usePageFunc = () => {
  const formattedString = (dateTime: Date | undefined): string => {
    const originalDate = dateTime ? new Date(dateTime) : new Date();
    const date = originalDate.toLocaleDateString().replace(/\//g, '.');
    const time = originalDate.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    return `${date} ${time}`;
  };

  const getStatus = (status: string): string => {
    switch (status) {
      case 'NEW':
        return '진행 전';
      case 'PROGRESS':
        return '진행 중';
      case 'COMPLETE_TASK':
        return '완료';
      case 'REJECT':
        return '반려';
      case 'STOP':
        return '중지';
      default:
        return '진행 전';
    }
  };

  //todo 함수는 아니지만 일단 여기 모아둔다...

  const workerType = ['TRANSLATOR', 'FIRST_CHECKER', 'SECOND_CHECKER'];

  const taskAmountRate = {
    PERCENT_101: 0,
    PERCENT_REP: 0.1,
    PERCENT_100: 0.1,
    PERCENT_9599: 0.7,
    PERCENT_8594: 0.7,
    PERCENT_7584: 0.7,
    PERCENT_5074: 1.0,
    NO_MATCH: 1.0
  };

  const taskAmountText: { [key: string]: any } = {
    PERCENT_101: '101%',
    PERCENT_REP: 'Rep',
    PERCENT_100: '100%',
    PERCENT_9599: '95%~99%',
    PERCENT_8594: '85%~94%',
    PERCENT_7584: '75%~84%',
    PERCENT_5074: '50%~74%',
    NO_MATCH: 'No match'
  };

  const getDateTime = ({
    date,
    filter = undefined
  }: {
    date: Date;
    filter?: 'start' | 'end';
  }) => {
    const utcDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);
    const resultDate = new Date(
      Date.UTC(
        utcDate.getUTCFullYear(),
        utcDate.getUTCMonth(),
        utcDate.getUTCDate(),
        utcDate.getUTCHours(),
        utcDate.getUTCMinutes(),
        utcDate.getUTCSeconds(),
        utcDate.getUTCMilliseconds()
      )
    );

    switch (filter) {
      case 'start':
        return new Date(resultDate.setUTCHours(0, 0, 0, 0));
      case 'end':
        return new Date(resultDate.setUTCHours(23, 59, 59, 0));
      default:
        return resultDate;
    }
  };

  return {
    getDateTime,
    formattedString,
    getStatus,
    taskAmountRate,
    taskAmountText,
    workerType
  };
};
