import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '@hooks/auth/useAuth';
import { useQueryClient } from '@tanstack/react-query';
import { useAlert } from '@hooks/modal/useAlert';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';
import { authKeys } from '@hooks/queryKeyFactory';

const AuthPage = () => {
  const { myInfo, isSuccess, getMyInfo } = useMyInfo();
  const { openHandle } = useAlert();
  const { signIn, signOut } = useAuth();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isSuccess && myInfo) {
      myInfo?.roles?.includes('ADMIN') && signIn();
    } else {
      if (localStorage.getItem('accessToken')) {
        getMyInfo().then();
      } else {
        signOut();
      }
    }
    return () => {};
  }, [myInfo, pathname]);

  useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (err) => {
          if ((err as any).status === 'UNAUTHORIZED') {
            openHandle({
              title: '로그인 만료',
              text: '로그인이 만료되었습니다.',
              ok: () => {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('accessTokenExpiresIn');
                queryClient.invalidateQueries([...authKeys.myInfo]);
                signOut();
              }
            });
          }
        }
      }
    });
  }, []);

  return <Outlet />;
};

export default AuthPage;
