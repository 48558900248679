import { useGlobalValue, useSetGlobalState } from '@src/hooks/useGlobalState';
import { globalKeys } from '../queryKeyFactory';
import React from 'react';
import { Snackbar } from '@mui/material';
import { IconToastFail, IconToastSuccess } from '@resources/icon';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Color } from '@src/common/styles/color';

const ToastStyle = styled.div<{
  severity?: 'success' | 'error';
}>`
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 12px 0;
  font-size: 14px;
  color: #fafafb;
  background: linear-gradient(270deg, #aa51e0 0%, #9b51e0 100%);
  padding: 13px 16px;
  > span {
    margin-left: 16px;
  }

  ${({ severity }) => {
    if (severity === 'error')
      return css`
        background: ${Color.error};
      `;
  }}
`;

/**
 * dateRangePicker hook
 * @return {openHandler: (data: { text?: string; severity?: Severity }) => void, Component: () => JSX.Element};
 */
export const useToast = () => {
  //#region global state
  const toastData = useGlobalValue([...globalKeys.toast], {
    open: false,
    text: '',
    severity: 'success'
  });
  const toastFetcher = useSetGlobalState([...globalKeys.toast]);
  const { open, text, severity } = toastData;
  //#endregion

  //#region handler
  const openHandle = (data: {
    text?: string;
    severity?: 'success' | 'error';
  }) => {
    toastFetcher({
      text: data.text,
      severity: data.severity,
      open: true
    });
  };
  const closeHandle = () => {
    toastFetcher({ ...toastData, open: false });
  };
  //#endregion

  //#region component
  const Component = (): JSX.Element =>
    open && (
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={closeHandle}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <ToastStyle severity={'success'}>
          {severity === 'success' ? (
            <IconToastSuccess width={20} height={20} />
          ) : (
            <IconToastFail width={20} height={20} />
          )}
          <span> {text}</span>
        </ToastStyle>
      </Snackbar>
    );

  return {
    openHandle,
    Component
  };
  //#endregion
};
