import { useToast } from '@hooks/modal/useToast';
import { useAlert } from '@hooks/modal/useAlert';
import { useProgress } from '@hooks/modal/useProgress';

export const useModalContainer = () => {
  const { Component: Toast } = useToast();
  const { Component: Alert } = useAlert();
  const { Component: Progress } = useProgress();

  const ModalContainer = () => (
    <>
      <Toast />
      <Alert />
      <Progress />
    </>
  );

  return { ModalContainer };
};
