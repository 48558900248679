import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useGetEmailValid } from '@hooks/users/getEmailValid';
import { useGetSlackUserProfile } from '@hooks/users/getSlackUserProfile';
import { useToast } from '@hooks/modal/useToast';
import { useAlert } from '@hooks/modal/useAlert';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

/**
 * @description 유저 계정 생성 /users
 * @returns {email: string,
 *     setEmail: function,
 *     validEmail: function,
 *     name: string,
 *     setName: function,
 *     slackId: string,
 *     changeSlackId: function,
 *     validSlackId: function,
 *     roleList: string[],
 *     changeRoleList: function,
 *     avatarImgUrl: string,
 *     validInfo: IValidInfo,
 *     clickCreate: function,
 *     clickClose: function}
 */
export const usePostUsers = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openHandle: alertHandle } = useAlert();
  const { openHandle } = useToast();
  const { emailValid, isEmailValid, isEmailLoading } = useGetEmailValid();
  const {
    data: validSlackIdData,
    slackIdValid,
    slackIdError
  } = useGetSlackUserProfile();
  //#region state
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [slackId, setSlackId] = useState('');
  const [roleList, setRoleList] = useState<string[]>([]);
  const [avatarImgUrl, setAvatarImgUrl] = useState('');
  const [validInfo, setValidInfo] = useState<{
    email: '' | 'valid' | 'invalid' | 'invalid:regex';
    name: '' | 'valid' | 'invalid';
    slackId: '' | 'valid' | 'invalid' | 'invalid:regex';
    roleList: '' | 'valid' | 'invalid';
  }>({
    email: '',
    name: '',
    slackId: '',
    roleList: ''
  });
  //#endregion

  const validEmail = () => {
    emailValid(email);
  };

  const changeSlackId = (slackId: string) => {
    const regex = /^[a-zA-Z0-9]*$/;
    if (!regex.test(slackId)) return;
    setSlackId(slackId);
  };

  const validSlackId = () => {
    slackIdValid(slackId);
  };

  const changeRoleList = (role: string) => {
    if (_.includes(roleList, role)) {
      setRoleList(_.filter(roleList, (item) => item !== role));
    } else {
      setRoleList([...roleList, role]);
    }
  };

  const clickClose = () => {
    alertHandle({
      title: '작성 취소',
      text: '입력 중인 내용이 모두 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        navigate('/account');
      }
    });
  };

  const clickCreate = () => {
    alertHandle({
      title: '등록 확인',
      text: '입력한 내용으로 등록하시겠습니까?',
      cancelLabel: '취소',
      ok: () => {
        createUser();
        navigate('/account', { replace: true });
      }
    });
  };

  //#region api call
  // TODO: 향후 단일체크 UI 를 개발해야 함.
  // https://api-dev.boracat.io/admin/swagger-ui/index.html#/%EC%9C%A0%EC%A0%80(%EA%B3%84%EC%A0%95%EA%B4%80%EB%A6%AC)%20API/createUser
  // enum privacyAgree { REQUIRED = 'REQUIRED', DONE = 'DONE' }
  const getData = async () => {
    if (
      _.includes(validInfo, 'invalid') ||
      _.includes(validInfo, 'invalid:regex') ||
      _.includes(validInfo, '')
    )
      return;
    return await api
      .post('/users', {
        email,
        name,
        slackId,
        roleList,
        avatarImgUrl,
        privacyAgree: 'DONE'
      })
      .then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { mutate: createUser } = useMutation({
    mutationFn: getData,
    onSuccess: () => {
      openHandle({ text: '계정이 생성되었습니다.', severity: 'success' });
      queryClient.invalidateQueries(['/users/list']);
    },
    onError: () => {
      openHandle({
        text: `계정 생성에 실패하였습니다.`,
        severity: 'error'
      });
    }
  });
  //#endregion

  useEffect(() => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const valid = !isEmailValid
      ? regex.test(email)
        ? 'valid'
        : 'invalid:regex'
      : regex.test(email)
      ? 'invalid'
      : 'invalid:regex';
    setValidInfo({
      ...validInfo,
      email: valid as 'valid' | 'invalid' | 'invalid:regex'
    });
    return () => {};
  }, [isEmailLoading]);

  useEffect(() => {
    setValidInfo({
      ...validInfo,
      name: !!name.length ? 'valid' : 'invalid'
    });
    return () => {};
  }, [name]);

  useEffect(() => {
    setValidInfo({
      ...validInfo,
      roleList: !!roleList.length ? 'valid' : 'invalid'
    });
    return () => {};
  }, [roleList]);

  useEffect(() => {
    if (slackIdError) {
      setValidInfo({
        ...validInfo,
        slackId: 'invalid'
      });
    } else {
      if (validSlackIdData?.ok) {
        setAvatarImgUrl(validSlackIdData?.profile?.image512);
        setValidInfo({
          ...validInfo,
          slackId: 'valid'
        });
      } else {
        setValidInfo({
          ...validInfo,
          slackId: 'invalid:regex'
        });
        setAvatarImgUrl('');
      }
    }
  }, [validSlackIdData, slackIdError]);

  useEffect(() => {
    setValidInfo({
      ...validInfo
    });
    return () => {};
  }, []);

  return {
    email,
    setEmail,
    validEmail,
    name,
    setName,
    slackId,
    changeSlackId,
    validSlackId,
    roleList,
    changeRoleList,
    avatarImgUrl,
    validInfo,
    clickCreate,
    clickClose
  };
};
