import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const FilterWrap = styled.div<{
  fullWidth?: boolean;
  height?: string;
}>`
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : null)};
  height: ${({ height }) => (height ? height : '48px')};
`;

export const FilterInput = styled.div<{
  unable?: boolean;
  fontSize?: string;
  optionLeftMargin?: string;
}>`
  border: 1px solid #d5d3e4;
  border-radius: 12px;
  padding: 0 16px 0 20px;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #2c3558;
  transition: 0.3s;
  font-size: 14px;
  :hover {
    color: #9b51e0;
    border-color: #9b51e0;
    cursor: pointer;
  }
  .label--checked {
    display: flex;
    gap: 8px;

    .label {
    }
    .checked {
    }
  }
  .label--selectOption {
    height: 100%;
    display: flex;
    align-items: center;
    .label {
      color: #2c3558;
    }
    .selectOption {
      margin-left: ${({ optionLeftMargin }) =>
        optionLeftMargin ? optionLeftMargin : '8px'};
      font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
      &.colorGray {
        color: #9a9cb5;
      }
    }
  }

  .icon {
    margin-left: 4px;
    font-size: 26px;
  }

  ${({ unable }) => {
    if (unable) {
      return css`
        opacity: 0.4;
        :hover {
          color: #2c3558;
          border-color: #d5d3e4;
          cursor: not-allowed;
        }
      `;
    }
  }}
`;

export const Gap = styled.div`
  height: 3px;
`;

export const SelectList = styled.div<{
  showCheckList: boolean;
}>`
  position: absolute;
  border: 1px solid #d5d3e4;
  border-radius: 12px;
  padding: 12px 0;
  max-height: 496px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${({ showCheckList }) => (showCheckList ? 1 : 0)};
  color: #2c3558;
  z-index: 1060;
  transition: 0.3s;
  white-space: nowrap;
  overflow: overlay;
  @supports not (overflow: overlay) {
    overflow: auto;
  }

  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d5d3e4;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-track {
    margin: 4px;
  }
`;
