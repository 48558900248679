import axios from 'axios';
import { format } from 'date-fns';

/**
 * @description 에러 로그 인터페이스
 * @interface IErrorDoc
 * @property {string} version 버전
 * @property {string} message 에러 메시지
 * @property {string} token 사용자 토큰
 * @property {string} created 에러 발생 시간
 * @property {string} userAgent 사용자 정보
 */
interface IErrorDoc {
  version: string;
  message: string;
  token: string;
  created: string;
  userAgent: string;
}

const Slack = {
  // 2p_ channel에 메시지 전송
  sendMessageToErrorChannel: (message: string) => {
    axios.post(
      process.env.REACT_APP_SLACK_WEBHOOK as string,
      { text: message },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }
};

const Logger = {
  debug: (message: string) => {
    console.debug(message);
  },
  info: (message: string) => {
    console.info(message);
  },
  warn: (message: string) => {
    console.warn(message);
  },
  error: (message: string, type?: string) => {
    console.error(message);
    try {
      let token = localStorage.getItem('accessToken');
      let errorDoc = {} as IErrorDoc;
      errorDoc.message = message;
      errorDoc.token = token || 'Not token';
      errorDoc.created = format(new Date(), 'YYYY-MM-DD HH:mm:ss');
      errorDoc.userAgent = navigator.userAgent || '';
      errorDoc.message = message.substring(0, 2500);
      if (type) {
        Slack.sendMessageToErrorChannel(
          `Admin ${type} : ${JSON.stringify(errorDoc)}`
        );
      } else {
        Slack.sendMessageToErrorChannel(
          `Admin SERVER : ${JSON.stringify(errorDoc)}`
        );
      }
    } catch (e) {
      console.error('Logger error : ' + JSON.stringify(e));
    }
  },
  log: function (message: string) {
    console.log(message);
  }
};

export default Logger;
