import React, { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CommonStyle } from '@pages/modal/Common.style';
import { alertWrap } from '@hooks/modal/Modal.style';
import { IconModalClose } from '@resources/icon';
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import { Box } from '@mui/material';
import { usePostLanguageSpec } from '@hooks/languageSpec/postLanguageSpec';
import { TPSelectBasic } from '@units/select/TPSelect.basic';
import { getLanguage, LanguageCodeType } from '@common/Code';

const LanguagePairModifyPage = () => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [searchParams] = useSearchParams();

  const {
    initData,
    languageSpecUserList,
    mainLanguageLeader,
    changeMainLanguageLeader,
    subLanguageLeader,
    changeSubLanguageLeader,
    worker,
    changeWorker,
    putLanguageSpecData,
    closeModal
  } = usePostLanguageSpec(
    searchParams.get('start') as string,
    searchParams.get('destination') as string
  );
  const { mainLanguageLeaderList, subLanguageLeaderList, workerList } =
    languageSpecUserList;

  return (
    <div css={[CommonStyle, alertWrap]}>
      <button className="alertClose" onClick={closeModal}>
        <IconModalClose />
      </button>
      <div>
        <TPHeadlineBasic decoration type="h2">
          {`${
            initData && initData.startLanguage
              ? getLanguage([initData.startLanguage as LanguageCodeType])[0]
                  .label
              : ''
          } → ${
            initData && initData.destinationLanguage
              ? getLanguage([
                  initData.destinationLanguage as LanguageCodeType
                ])[0].label
              : ''
          } 담당자 변경`}
        </TPHeadlineBasic>
      </div>
      <div ref={scrollRef} className="modal--div--box non-scroll">
        <div>
          <div className="title">
            메인언어리더 <span className="color--red">*</span>
          </div>
          <TPSelectBasic
            large={true}
            value={mainLanguageLeader}
            label={'메인언어리더'}
            options={mainLanguageLeaderList}
            changeSelect={(e) => {
              changeMainLanguageLeader(e.target.value);
            }}
            autoWidth={false}
            isDisabled={false}
            hidePlaceholder={true}
          />
        </div>
        <div>
          <div className="title">서브언어리더</div>
          <TPSelectBasic
            large={true}
            multiple={true}
            value={subLanguageLeader}
            label={'서브언어리더'}
            options={subLanguageLeaderList}
            changeSelect={(e) => {
              changeSubLanguageLeader(e.target.value);
            }}
            autoWidth={false}
            isDisabled={!mainLanguageLeader}
            hideValue={true}
          />
        </div>
        <div>
          <div className="title">작업자</div>
          <TPSelectBasic
            large={true}
            multiple={true}
            value={worker}
            label={'작업자'}
            options={workerList}
            changeSelect={(e) => {
              changeWorker(e.target.value);
            }}
            autoWidth={false}
            isDisabled={!mainLanguageLeader}
            hideValue={true}
          />
        </div>
      </div>
      <Box display={'flex'} justifyContent={'space-between'} gap={2} mt={8}>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="outlined"
            label="취소하기"
            fullWidth
            onClick={closeModal}
            size={'large'}
          />
        </Box>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="contained"
            label="변경하기"
            fullWidth
            disabled={false}
            onClick={putLanguageSpecData}
            size={'large'}
          />
        </Box>
      </Box>
    </div>
  );
};

export default LanguagePairModifyPage;
