import { ReactElement, cloneElement } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { TPTooltipTheme } from './TPTooltip.Theme';

/**
 * @description Tooltip
 * @param props
 */
export const TPTooltipBasic = (props: {
  title: string | ReactElement;
  children: ReactElement;
  placement?:
    | 'top-start'
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top'
    | undefined;
  size?: string;
  description?: boolean;
  className?: string;
}) => {
  const {
    title = '',
    placement = 'top',
    children,
    size,
    description = false,
    className
  } = props;

  return (
    <ThemeProvider theme={TPTooltipTheme}>
      <Tooltip
        title={title}
        placement={placement}
        arrow={!description}
        className={className ? className : ''}
        classes={
          description ? { popper: 'tooltip--description' } : { popper: '' }
        }
      >
        {size ? cloneElement(children, { ...children.props, size }) : children}
      </Tooltip>
    </ThemeProvider>
  );
};
