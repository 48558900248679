import styled from '@emotion/styled';

export const TPHeadline2Theme = styled.h2`
  display: inline-block;
  position: relative;
  font-size: 2rem;
  line-height: 140%;
  letter-spacing: -1px;
  color: #2c3558;
  overflow: hidden;
  text-align: justify;
  white-space: pre-line;
  word-break: break-all;
  span {
    position: relative;
  }
  &.decoration::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 4px;
    width: 100%;
    height: 12px;
    background: rgba(113, 216, 142, 0.6);
  }
`;

export const TPHeadline3Theme = styled.h3`
  display: inline-block;
  position: relative;
  font-size: 1.25rem;
  letter-spacing: -0.5px;
  overflow: hidden;
  white-space: normal;
  word-break: break-all;
  span {
    position: relative;
  }
  &.decoration::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 2px;
    width: 100%;
    height: 6px;
    background: rgba(113, 216, 142, 0.6);
  }
`;
