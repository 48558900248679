import { useGlobalValue, useSetGlobalState } from '@src/hooks/useGlobalState';
import { useState } from 'react';
import { addDays, addMonths, format } from 'date-fns';
import { DayPicker, DateRange, DateFormatter } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { CalendarWrap, CalendarStyle } from '@hooks/modal/Calendar.style';
import { IconModalClose } from '@resources/icon';
import { TPButtonBasic } from '@units/button/TPButton.Basic';

/**
 * 달력 caption format (년. 월)
 * @type {(month: Date) => JSX.Element}
 * @param month
 */
const formatCaption: DateFormatter = (month: Date) => (
  <>
    {`${month.getFullYear()}. ${month.getMonth() + 1 <= 9 ? 0 : ''}${
      month.getMonth() + 1
    }`}
  </>
);

/**
 * 달력 요일 format (Mo, Tu, We, Th, Fr, Sa, Su)
 * @type {(weekday: Date) => JSX.Element}
 * @param weekday
 */
const formatWeekdayName: DateFormatter = (weekday: Date): JSX.Element => (
  <>{format(weekday, 'eeeeee')}</>
);

/**
 * @description dateRangePicker hook
 * @param props
 */
export const useRangeCalendar = (props?: {
  dateType: 'dateRange' | string;
  dateLimit?: {
    fromLimit?: Date | string;
    toLimit?: Date | string;
  };
}) => {
  const nextMonth = addMonths(new Date(), 0);
  const [month, setMonth] = useState<Date>(nextMonth);

  //#region props
  const { dateType } = props ?? { dateType: 'dateRange' };
  const { fromLimit, toLimit } = props?.dateLimit ?? {
    fromLimit: undefined,
    toLimit: undefined
  };
  //#endregion

  //#region global state
  const calenderData = useGlobalValue([dateType], {
    open: false,
    setDate: () => {}
  });
  const { setDate } = calenderData;
  const modalFetcher = useSetGlobalState([dateType]);
  //#endregion

  //#region state
  const [selectRange, setSelectRange] = useState<DateRange | undefined>({
    from: new Date(),
    to: addDays(new Date(), 0)
  });
  //#endregion

  //#region handler
  const openHandle = (
    dateType: string,
    dateRange:
      | { startDateTime?: Date | string; endDateTime?: Date | string }
      | undefined,
    setDate: (el?: any) => void
  ) => {
    modalFetcher({ ...calenderData, open: true, dateRange, dateType, setDate });
    const date = dateRange
      ? {
          from: dateRange?.startDateTime
            ? new Date(dateRange?.startDateTime as string)
            : new Date(),
          to: dateRange?.endDateTime
            ? new Date(dateRange?.endDateTime as string)
            : addDays(new Date(), 0)
        }
      : {
          from: new Date(),
          to: addDays(new Date(), 0)
        };
    setSelectRange(date);
  };

  const closeHandle = () => {
    modalFetcher({ calenderData, open: false });
  };

  const clickConfirm = (fromTo: DateRange | undefined) => {
    let confirmRange = JSON.parse(JSON.stringify(fromTo));
    setDate({
      startDateTime: confirmRange?.from,
      endDateTime: confirmRange?.to
    });
    closeHandle();
  };
  //#endregion

  //#region component
  const Component = (): JSX.Element =>
    calenderData.open && (
      <div css={CalendarWrap} className={'range'}>
        <div className="top">
          <button className="close-button" onClick={closeHandle}>
            <IconModalClose />
          </button>
        </div>
        <div className="middle">
          <DayPicker
            month={month}
            onMonthChange={setMonth}
            formatters={{ formatCaption, formatWeekdayName }}
            css={CalendarStyle}
            mode={'range'}
            numberOfMonths={2}
            selected={selectRange}
            weekStartsOn={1}
            onSelect={setSelectRange}
            fromDate={fromLimit ? new Date(fromLimit) : undefined}
            toDate={toLimit ? new Date(toLimit) : undefined}
          />
        </div>
        <div className="bottom">{/* todo :  추후 확인 */}</div>
        <div className="buttonWrap">
          <div className="cancelButton">
            <TPButtonBasic
              fullWidth
              label="취소"
              size="large"
              variant="outlined"
              onClick={closeHandle}
            />
          </div>
          <div className="confirmButton">
            <TPButtonBasic
              fullWidth
              disabled={
                selectRange?.from === undefined || selectRange?.to === undefined
              }
              label="확인"
              size="large"
              variant="contained"
              onClick={() => {
                clickConfirm(selectRange);
              }}
            />
          </div>
        </div>
      </div>
    );

  return {
    closeHandle,
    openHandle,
    Component
  };
  //#endregion
};
