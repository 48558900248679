import React, { useRef } from 'react';
import { CommonStyle } from '@pages/modal/Common.style';
import { alertWrap } from '@hooks/modal/Modal.style';
import { IconCalendar, IconModalClose } from '@resources/icon';
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { TPInputText } from '@units/input/TPInput.text';
import { TPButtonBasic } from '@units/button/TPButton.Basic';
import { TPInputCheckBox } from '@units/input/TPInput.checkBox';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import { TPButtonActionCalendar } from '@units/button/TPButton.ActionCalendar';
import { useRangeCalendar } from '@hooks/modal/useRangeCalendar';
import { usePostProject } from '@hooks/project/postProject';
import { TPSelectBasic } from '@units/select/TPSelect.basic';
import _ from 'lodash';
import { Color } from '@common/styles/color';

const ProjectRegisterPage = () => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const { openHandle, Component: Calendar } = useRangeCalendar({
    dateType: 'dateRange'
    // dateLimit: { fromLimit: new Date() }
  });
  const {
    machineTranslationTypeList,
    machineTranslationType,
    setMachineTranslationType,
    prompt,
    setPrompt,
    convertCompanyList,
    projectTitle,
    changeProjectTitle,
    validTitleFunc,
    projectKey,
    changeKey,
    validKeyFunc,
    companyId,
    changeCompanyId,
    contractType,
    setContractType,
    dateFilter,
    setDateFilter,
    validInfo,
    clickCreate,
    closeModal
  } = usePostProject();

  return (
    <div css={[CommonStyle, alertWrap]}>
      <button className="alertClose" onClick={closeModal}>
        <IconModalClose />
      </button>
      <div>
        <TPHeadlineBasic decoration type="h2">
          신규 프로젝트 등록
        </TPHeadlineBasic>
      </div>
      <div ref={scrollRef} className="modal--div--box">
        <div className={'input--text--wrap'}>
          <TPInputText
            label={'프로젝트명'}
            name={'title'}
            value={projectTitle}
            changeText={(e: any) => {
              changeProjectTitle(e.target.value);
            }}
            required={true}
            placeholder={'프로젝트명을 입력해 주세요.'}
            helperText={
              _.includes(validInfo.projectTitle, 'invalid') ? (
                validInfo.projectTitle === 'invalid:regex' ? (
                  <span>중복된 프로젝트명입니다.</span>
                ) : (
                  <span>프로젝트명은 필수입니다.</span>
                )
              ) : validInfo.projectTitle === 'valid' ? (
                <span style={{ color: Color.black }}>
                  등록 가능한 프로젝트명입니다.
                </span>
              ) : (
                ''
              )
            }
          />
          <TPButtonBasic
            size={'large'}
            label={'중복 확인'}
            variant={'contained'}
            onClick={validTitleFunc}
          />
        </div>
        <div className={'input--text--wrap'}>
          <TPInputText
            label={'프로젝트키'}
            name={'key'}
            value={projectKey}
            changeText={(e: any) => {
              changeKey(e.target.value);
            }}
            required={true}
            placeholder={'영어 대문자, 숫자만 입력가능합니다.'}
            helperText={
              _.includes(validInfo.projectKey, 'invalid') ? (
                validInfo.projectKey === 'invalid:regex' ? (
                  <span>중복된 프로젝트키입니다.</span>
                ) : (
                  <span>프로젝트키는 필수입니다.</span>
                )
              ) : validInfo.projectKey === 'valid' ? (
                <span style={{ color: Color.black }}>
                  등록 가능한 프로젝트키입니다.
                </span>
              ) : (
                ''
              )
            }
          />
          <TPButtonBasic
            size={'large'}
            label={'중복 확인'}
            variant={'contained'}
            onClick={validKeyFunc}
          />
        </div>
        <div>
          <div className="title">
            고객사 <span className="color--red">*</span>
          </div>

          <TPSelectBasic
            large={true}
            value={companyId}
            options={convertCompanyList}
            changeSelect={(e) => {
              changeCompanyId(e.target.value);
            }}
            autoWidth={false}
            isDisabled={false}
            placeholder={'고객사를 선택해 주세요.'}
          />

          {validInfo.companyId === 'invalid' ? (
            <p className="valid">* 고객사를 선택해주세요.</p>
          ) : null}
        </div>
        <div>
          <div className="title">
            견적 유형 <span className="color--red">*</span>
          </div>
          <div className={'input--checkbox--wrap'}>
            <TPInputCheckBox
              option={'건별 발송'}
              optionKey={'CASE'}
              selectOptions={[contractType]}
              onClick={setContractType}
            />
            <TPInputCheckBox
              option={'월별 발송'}
              optionKey={'MONTHLY'}
              selectOptions={[contractType]}
              onClick={setContractType}
            />
          </div>
        </div>
        <div>
          <div className="title">
            계약기간 <span className="color--red">*</span>
          </div>
          <TPButtonActionCalendar
            onClick={() => {
              openHandle('projectRegister', dateFilter, setDateFilter);
            }}
            outline={true}
            size={'medium'}
            content={
              dateFilter.startDateTime && dateFilter.endDateTime
                ? `${format(
                    new Date(dateFilter.startDateTime),
                    'yyyy.MM.dd'
                  )} - ${format(
                    new Date(dateFilter.endDateTime),
                    'yyyy.MM.dd'
                  )}`
                : '시작일과 종료일을 입력해주세요.'
            }
            icon={<IconCalendar />}
          />
          <Calendar />
          {validInfo.dateFilter === 'invalid' ? (
            <p className="valid">* 계약기간을 입력해주세요.</p>
          ) : null}
        </div>
        <div>
          <div className="title">기계번역 유형</div>

          <TPSelectBasic
            large={true}
            value={machineTranslationType}
            options={[...machineTranslationTypeList]}
            changeSelect={(e) => {
              setMachineTranslationType(e.target.value);
            }}
            autoWidth={false}
            isDisabled={false}
          />
        </div>
        <div>
          <TPInputText
            label={'프롬프트'}
            name={'propmt'}
            value={prompt}
            changeText={(e: any) => {
              setPrompt(e.target.value);
            }}
            placeholder={'프롬프트를 입력해 주세요.'}
          />
        </div>
      </div>

      <Box display={'flex'} justifyContent={'space-between'} gap={2} mt={8}>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="outlined"
            label="취소하기"
            fullWidth
            onClick={closeModal}
            size={'large'}
          />
        </Box>
        <Box flexGrow={1}>
          <TPButtonBasic
            variant="contained"
            label="등록하기"
            fullWidth
            disabled={
              _.includes(validInfo, 'invalid') ||
              _.includes(validInfo, 'invalid:regex') ||
              _.includes(validInfo, '')
            }
            onClick={clickCreate}
            size={'large'}
          />
        </Box>
      </Box>
    </div>
  );
};

export default ProjectRegisterPage;
