import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@hooks/modal/useToast';

/**
 * /languageSpec
 * @description 언어페어 담당자 변경 API
 * @returns { putLanguageSpec: function }
 */
export const usePutLanguageSpec = () => {
  const queryClient = useQueryClient();
  const { openHandle } = useToast();

  //#region api call
  const getData = async (payload: {
    startLanguage: string;
    destinationLanguage: string;
    mainLanguageLeaderUserId: string;
    subLanguageLeaderUserIdList: string[];
    workUserIdList: string[];
  }) => {
    return await api.put('/languageSpec', payload).then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { mutate: putLanguageSpec } = useMutation({
    mutationFn: getData,
    onSuccess: () => {
      openHandle({ text: '담당자가 변경되었습니다.', severity: 'success' });
      queryClient.invalidateQueries(['/languageSpec/list']);
    },
    onError: () => {
      openHandle({
        text: `담당자 변경에 실패하였습니다.`,
        severity: 'error'
      });
    }
  });
  //#endregion

  return {
    putLanguageSpec
  };
};
