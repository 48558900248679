import { useAuthorizationGoogle } from '@hooks/auth/postAuthGoogle';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

export const useAuth = () => {
  const queryClient = useQueryClient();
  const { googleSignOut } = useAuthorizationGoogle();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;

  const signIn = () => {
    if (pathname !== '/login' && pathname !== '/') {
      navigate(`${pathname}${search ? search : ''}`, { replace: true });
      return;
    }

    if (sessionStorage.getItem('directPath') !== null) {
      navigate(sessionStorage.getItem('directPath') as string, {
        replace: true
      });
      sessionStorage.removeItem('directPath');
      return;
    }

    navigate('/account', { replace: true });
  };

  const signOut = () => {
    if (pathname !== '/login') {
      if (localStorage.getItem('accessToken') === null) {
        if (pathname !== '/') sessionStorage.setItem('directPath', pathname);
      }
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessTokenExpiresIn');
      queryClient.invalidateQueries([
        '/auth/authorization/google',
        'https://www.googleapis.com/oauth2/v2/userinfo'
      ]);
      navigate('/login', { replace: true });
      googleSignOut();
    }
  };

  return { signIn, signOut };
};
