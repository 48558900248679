import { headerWrap, retruHeaderWrap } from './Header.style';
import { TPAvatarBasic } from '@units/avatar/TPAvatar.Basic';
import { TPTooltipBasic } from '@units/tooltip/TPTooltip.Basic';
import { IconBulLeftUrl } from '@resources/icon';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '@hooks/auth/useAuth';
import { useMyInfo } from '@src/hooks/myInfo/getMyInfo';

function Header() {
  const { signOut } = useAuth();
  const { myInfo } = useMyInfo();

  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const returnUrlBtn: boolean =
    location.pathname.includes('/project/') && Object.keys(params).length > 0;

  const returnUrl = () => navigate(-1);

  return (
    <>
      <header css={returnUrlBtn ? retruHeaderWrap : headerWrap}>
        {returnUrlBtn && (
          <button className="header--button--return" onClick={returnUrl}>
            <img src={IconBulLeftUrl} alt="" />
          </button>
        )}
        <div className={'header--dl--userinfo'}>
          <dl>
            <dt>{myInfo?.name}</dt>
            <dd>{myInfo?.email}</dd>
          </dl>
          <TPTooltipBasic
            description
            placement="top"
            title={<button onClick={signOut}>로그아웃</button>}
          >
            <TPAvatarBasic
              alt={''}
              badge={false}
              size="small"
              src={myInfo?.avatarImgUrl}
            />
          </TPTooltipBasic>
        </div>
      </header>
    </>
  );
}
export default Header;
