import { ThemeProvider } from '@mui/material/styles';
import { TPInputRadioTheme } from './TPInput.theme';
import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { IconChecked, IconUnChecked } from '@resources/icon';
import { ChangeEvent, ReactElement } from 'react';

/**
 * @description Radio button
 * @param props
 */
export const TPInputRadio = (props: {
  value?: string;
  label?: string;
  required?: boolean;
  name: string;
  options: { label: string; value: string }[];
  icon?: ReactElement;
  unCheckIcon?: ReactElement;
  mt?: number;
  changeRadio?: (e: ChangeEvent) => void;
}) => {
  const {
    value,
    label,
    required = false,
    name,
    options = [],
    icon = <IconChecked width={24} height={24} />,
    unCheckIcon = <IconUnChecked width={24} height={24} />,
    mt = 0,
    changeRadio = () => {}
  } = props;

  return (
    <ThemeProvider theme={TPInputRadioTheme}>
      {label ? (
        <Box display={'flex'} textAlign={'left'} mt={mt}>
          <FormLabel htmlFor={name}>
            {label}
            {required ? (
              <Box component={'sup'} color="#E6311A">
                *
              </Box>
            ) : null}
          </FormLabel>
        </Box>
      ) : null}
      <Box mt={label ? 0.875 : mt}>
        <RadioGroup
          row
          aria-labelledby={label}
          value={value}
          name={name}
          onChange={changeRadio}
        >
          {options.map((item, index) => {
            let { value, label } = item;
            return (
              <FormControlLabel
                key={index}
                value={value}
                control={<Radio checkedIcon={icon} icon={unCheckIcon} />}
                label={label}
              />
            );
          })}
        </RadioGroup>
      </Box>
    </ThemeProvider>
  );
};
