import api from '@api/Api';
import { useMutation } from '@tanstack/react-query';

/**
 * /projectManage/projectTitleValid
 * @description 언어페어 리더, 작업자 전체 조회
 * @returns {titleValidData: any
 *     validTitleError: unknown,
 *     titleValid: function,
 *     validTitle: boolean}
 */
export const useGetProjectTitleValid = () => {
  //#region api call
  const getData = async (title: string) => {
    return await api
      .get('/projectManage/projectTitleValid', { projectTitle: title })
      .then((res) => {
        return res;
      });
  };
  //#endregion

  //#region useQuery define
  const {
    data: titleValidData,
    error: validTitleError,
    mutate: titleValid,
    isError: validTitle
  } = useMutation({
    mutationFn: getData
  });
  //#endregion

  return {
    titleValidData,
    validTitleError,
    titleValid,
    validTitle
  };
};
