import { css } from '@emotion/react';

export const CalendarWrap = css`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  border-radius: 26px;
  overflow: hidden;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  .rdp {
    margin: 0;
  }
  .top {
    position: relative;
    height: 40px;
    .close-button {
      position: absolute;
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 20px;
      right: 25px;
      top: 22px;
    }
  }
  .middle {
    width: 1000px;
    min-height: 421px;
  }

  .bottom {
    position: relative;
    padding: 40px 85px;
    display: flex;
    flex-direction: column;
    .timeValidation {
      line-height: 1.66;
      text-align: left;
      margin: 3px 14px 0;
      font-size: 0.75rem;
      color: #e6311a;
    }
    .select-Startline-Time,
    .select-Deadline-Time {
      border: 1px solid #d5d3e4;
      border-radius: 12px;
      width: 132px;
      height: 48px;
      display: flex;
      justify-content: flex-start;
      padding: 0 16px 0 20px;
      align-items: center;
      margin-left: 451px;
      position: relative;

      .IconWatch {
        width: 20px;
        height: 20px;
        opacity: 0.5;
      }
      .IconInputCheck {
        width: 12px;
        height: 20px;
        & * {
          fill: #646782;
        }
      }
      .content {
        border: none;
        width: 41px;
        margin: 0 8px;
        font-size: 14px;
      }
      .Icon {
        width: 24px;
        height: 24px;
        font-size: 20px;
      }
      span {
        position: absolute;
        bottom: -24px;
        left: 0;
        width: 200px;
      }

      &:focus {
        border-color: #9b51e0;
      }
    }
  }
  .buttonWrap {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-bottom: 51px;
    .cancelButton {
      width: 403px;
    }
    .confirmButton {
      width: 403px;
    }
  }

  &.single {
    .middle {
      width: 456px;
    }
    .bottom {
      padding: 20px 40px 40px;
      .select-Deadline-Time {
        margin-left: 0;
      }
    }
    .buttonWrap {
      .cancelButton {
        width: 40%;
      }
      .confirmButton {
        width: 40%;
      }
    }
    div.rdp-months {
      .rdp-month {
        .rdp-caption {
          > .rdp-caption_label {
            margin-left: 44px;
          }
          .rdp-nav_button_previous {
            right: auto;
            left: 40px;
            top: 50px;
            width: 24px;
            height: 24px;
          }
          .rdp-nav_button_next {
            left: 236px;
            top: 50px;
            width: 24px;
            height: 24px;
          }
        }
      }

      .rdp-table
        .rdp-tbody
        .rdp-row
        .rdp-cell
        .rdp-button_reset.rdp-button.rdp-day.rdp-day_selected {
        color: #fff;
        span:first-of-type {
          color: #fff;
        }
        background-color: #9b51e0;
      }
    }
  }
  .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
  }
  .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
  }
`;
export const CalendarStyle = css`
  div.rdp-months {
    justify-content: center;
    color: #2c3558;
    /*padding: 4rem 8.5rem;*/
    border-radius: 2.6rem;
    padding: 0 85px;
    margin: auto;

    .rdp-month.rdp-caption_start {
      margin-right: 35px;
    }
    .rdp-month.rdp-caption_end {
      margin-left: 35px;
    }
    .rdp-month {
      .rdp-caption {
        height: 45px;
        margin-bottom: 40px;
        display: flex;
        > .rdp-caption_label {
          display: block;
          font-size: 32px;
          line-height: 45px;
          padding: 0;
        }
      }

      .rdp-month.rdp-caption_start.rdp-caption {
      }
      .rdp-month.rdp-caption_end.rdp-caption {
      }

      .rdp-table {
        width: 376px;
        max-width: none;
        display: flex;
        flex-direction: column;
        .rdp-head {
          .rdp-head_row {
            margin: 4px 0;
            display: flex;
            justify-content: space-between;
          }
          .rdp-head_cell {
            text-transform: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
          }
          .rdp-head_cell:nth-of-type(7) {
            color: red;
          }
        }

        .rdp-tbody {
          /* width: 10rem; */
          display: flex;
          flex-direction: column;
          overflow: hidden;
          .rdp-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 4px 0;
            .rdp-cell {
              font-size: 14px;
              width: 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              .rdp-button_reset.rdp-button.rdp-day {
                border: none;
                :hover {
                  background-color: rgba(155, 81, 224, 0.08);
                }
                :active {
                  border: none;
                }
              }
              .rdp-button_reset.rdp-button.rdp-day.rdp-day_outside {
                color: #9a9cb5;
              }
              .rdp-button_reset.rdp-button.rdp-day.rdp-day_selected {
                //color: #2c3558;
                :active {
                  border: none;
                }
                &.rdp-day_range_middle {
                  color: #9b51e0;
                }
              }
              .rdp-button_reset.rdp-button.rdp-day.rdp-day_selected.rdp-day_range_start {
                color: #ffffff;
                background-color: #9b51e0;
                border: none;
                overflow: visible;
                z-index: 2;
                position: relative;
                transform: rotate(360);
                ::before {
                  content: '';
                  position: absolute;
                  border-radius: 20px 0 0 20px;
                  top: 0;
                  left: 20px;
                  width: 30px;
                  height: 40px;
                  background-color: #f7f1fd;
                  z-index: -1;
                }
                ::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: 40px;
                  border-radius: 20px;
                  height: 40px;
                  background-color: #9b51e0;
                  z-index: -1;
                }
              }
              .rdp-button_reset.rdp-button.rdp-day.rdp-day_selected.rdp-day_range_start.rdp-day_range_end {
                ::before {
                  content: none;
                }
                ::after {
                  content: none;
                }
              }
              .rdp-button_reset.rdp-button.rdp-day.rdp-day_selected.rdp-day_range_middle {
                background-color: #f7f1fd;
                overflow: visible;
                span {
                  z-index: 2;
                }
                ::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  right: 40px;
                  width: 8px;
                  height: 40px;
                  background-color: #f7f1fd;
                }
                ::before {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 40px;
                  width: 8px;
                  height: 40px;
                  background-color: #f7f1fd;
                }
              }
              .rdp-button_reset.rdp-button.rdp-day.rdp-day_selected.rdp-day_range_end {
                color: #ffffff;
                background-color: #9b51e0;
                border: none;
                overflow: visible;
                ::before {
                  content: '';
                  position: absolute;
                  border-radius: 0 20px 20px 0;
                  top: 0;
                  right: 20px;
                  width: 30px;
                  height: 40px;
                  background-color: #f7f1fd;
                  z-index: -1;
                }
                ::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: 40px;
                  border-radius: 20px;
                  height: 40px;
                  background-color: #9b51e0;
                  z-index: -1;
                }
              }
              .rdp-button_reset.rdp-button.rdp-day.rdp-day_selected.rdp-day_outside {
                border: none;
              }
            }
          }
        }
      }

      .rdp-nav_button {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 185px;
        border: none;
        :hover {
          border: none;
          background-color: #f7f1fd;
        }
      }
      .rdp-nav_button_previous {
        right: 20px;
      }
      .rdp-nav_button_next {
        left: 20px;
      }
      .rdp-nav_icon {
        color: #646782;
        width: 100%;
        height: 2.667rem;
      }
    }
  }
`;

export const TimeSelectorWrap = css`
  position: absolute;
  left: 0;
  bottom: 52px;
  border: 1px solid #d5d3e4;
  border-radius: 12px;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 9;
  .item {
    padding: 8px 24px;
    font-size: 14px;
    text-align: left;
    :hover {
      background-color: #f7f1fd;
    }
  }
`;
