import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from '@hooks/modal/useAlert';
import _ from 'lodash';

/**
 * /auth/authorization/google
 * @description google 로그인 인증
 * @returns
 */
export const useAuthorizationGoogle = () => {
  const { openHandle, closeHandle } = useAlert();
  const queryClient = useQueryClient();

  const errorGoogleAuth = (error: any) => {
    if (error.error !== 'idpiframe_initialization_failed') {
      let erorrMessage = `구글 로그인에 문제가 발생 하였습니다. 아래 고객센터로 문의해 주시기 바랍니다.`;
      erorrMessage += error.details ? `<br/>(${error.details})` : '';
      openHandle({
        text: erorrMessage,
        title: '구글 로그인 문제 발생',
        okLabel: 'help@2bytes.io',
        ok: () => {
          window.location.href = 'mailto:help@2bytes.io';
        }
      });
    }
  };

  const postData = async ({
    email,
    tokenId
  }: {
    email: string;
    tokenId: string | unknown;
  }) => {
    return (
      await api.post(`/auth/authorization/google`, {
        email,
        tokenId,
        loginSite: 'ADMIN'
      })
    ).data;
  };

  const { mutate: postAuthGoogle, error } = useMutation({
    mutationFn: postData,
    onError: (error: any) => {
      // 에러 핸들링
      openHandle({
        text: _.compact([
          // error?.clientMessage || error?.message,
          '어드민 계정 생성이 필요한 경우,',
          '<strong>보라캣개발팀</strong>에 문의하시기 바랍니다.'
        ]).join('<br/>'),
        title:
          (error?.clientMessage || error?.message) ?? '회원정보가 없습니다.',
        okLabel: '확인',
        ok: () => {
          closeHandle();
        }
      });
      googleSignOut();
    },
    onSuccess: async (data) => {
      await localStorage.setItem('accessToken', data.accessToken);
      await localStorage.setItem('refreshToken', data.refreshToken);
      await localStorage.setItem(
        'accessTokenExpiresIn',
        data.accessTokenExpiresIn
      );
      queryClient.invalidateQueries(['/auth/myInfo']);
    }
  });

  const googleSignOut = () => {
    if (window.gapi) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2 !== null) {
        auth2
          .signOut()
          .then(auth2.disconnect())
          .catch((e: any) => console.log(e));
      }
    }
  };

  return {
    postAuthGoogle,
    errorGoogleAuth,
    googleSignOut,
    error
  };
};
