import { AxiosRequestConfig } from 'axios';

import interceptor from '@api/Interceptor';
import interceptorTest from '@api/InterceptorTest';

/*
  Api 요청 공통 service
*/
const Api = {
  // http get method 요청 :
  get: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let configure = config || {};
    configure.params = data;
    return interceptor.get(url, configure);
  },

  getBora: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let configure = { ...config, baseURL: '/cat' } || {};
    configure.params = data;
    return interceptor.get(url, configure);
  },

  // http post method 요청
  post: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let body = data || {};
    return interceptor.post(url, body, config);
  },

  // http post method 요청
  postBora: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let body = data || {};
    return interceptor.post(url, body, { ...config, baseURL: '/cat' });
  },

  // http put method 요청
  put: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let body = data || {};
    return interceptor.put(url, body, config);
  },

  putBora: (url: string, data?: any, config?: AxiosRequestConfig) => {
    let body = data || {};
    return interceptor.put(url, body, { ...config, baseURL: '/cat' });
  },

  // http delete method 요청
  delete: (url: string, config?: AxiosRequestConfig) => {
    return interceptor.delete(url, config);
  }
};

export default Api;
