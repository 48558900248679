export const authKeys = {
  authorizationGoogle: ['/auth/authorization/google'] as const,
  authorizationGoogleUserInfo: [
    'https://www.googleapis.com/oauth2/v2/userinfo'
  ] as const,
  myInfo: ['/auth/myInfo'] as const
};

export const usersKeys = {
  detail: (id: string) => ['/users', id] as const,
  list: (filter: {
    searchType?: string;
    keyword?: string | '';
    userStatus?: string;
    roleList?: string[];
    avatarImgUrlStatus?: string;
  }) => ['/users/list', filter] as const
};

export const languageSpecKeys = {
  languageSpecUser: ['/languageSpec/languageSpecUser'] as const,
  searchFilter: ['/languageSpec/searchFilter'] as const,
  list: (filter: {
    startLanguageList?: string[];
    destinationLanguageList?: string[];
    mainLanguageLeaderUserId?: string;
    subLanguageLeaderUserIdList?: string[];
    workUserIdList?: string[];
  }) => ['/languageSpec/list', filter] as const
};

export const languageTaskKeys = {
  projectSimpleAllList: ['/projectManage/simpleAllList'] as const,
  list: (filter: { projectId: string; keyword: string }) =>
    ['/languageTask/list', filter] as const
};

export const projectKeys = {
  companyList: ['/projectManage/companyList'] as const,
  managerList: ['/projectManage/projectManagerList'] as const,
  detail: (id: string) => ['/projectManage', id] as const,
  allList: (filter: {
    searchType?: string;
    keyword?: string | '';
    projectStatus?: string[];
    projectManagerUserIdList?: string[];
    contractType?: string;
    exposureStatus?: string;
    dateType?: string;
    dateFilter?: { startDateTime: string; endDateTime: string };
  }) => ['/projectManage/allList', filter] as const
};

export const companyKeys = {
  detail: (id: string) => ['/company', id] as const,
  list: (filter: { companyName?: string; iconImgUrlStatus?: string }) =>
    ['/company/list', filter] as const
};

export const fileManagementKeys = {
  list: ['/file/list'] as const
};
