//lib
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
//etc
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { LanguageTaskWrap } from './LanguageTask.style';

//hooks
import { useGetProjectList } from '@src/hooks/languageTask/getProjectSimpleAllList';
import { TPInputSearchNew } from '@src/units/input/TPInput.searchNew';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import DataTable from '../component/DataTable';
import { languageTaskColumns } from './LanguageTask.columns';
import { useGetLanguageTaskList } from '@src/hooks/languageTask/getLanguageTaskList';

/**
 * 계정관리 페이지
 * route : /account
 * @returns
 */
function LanguageTaskPage() {
  // const navigate = useNavigate();
  const { selectElementOptionListForProjectSimpleAllList } =
    useGetProjectList();

  const {
    searchResultForSelectedProjectSimpleAllListItem,
    keyword,
    setKeyword,
    updateKeyword,
    projectId,
    setProjectId,
    isSort,
    setIsSort
  } = useGetLanguageTaskList();

  return (
    <section css={LanguageTaskWrap}>
      <Box mb={'26px'} display={'flex'}>
        <Box display={'flex'} flexDirection={'column'} flexGrow={1} ml={2}>
          <dl className="project--dl--info">
            <TPHeadlineBasic type="h2" textAlign="left">
              언어작업 목록
            </TPHeadlineBasic>
          </dl>
        </Box>
      </Box>

      <Box mb={3} display={'flex'} justifyContent={'space-between'}>
        <Box display={'inline-flex'} gap={'8px'}>
          <TPSelectCheck
            label={projectId ? '' : '프로젝트 선택'}
            useOptionLabel
            optionList={selectElementOptionListForProjectSimpleAllList}
            selectOptions={[String(projectId)]}
            changeSelect={setProjectId}
          />
          <TPInputSearchNew
            value={keyword}
            placeholder="작업명 검색"
            onChange={(e) => setKeyword(e.target.value)}
            clickSearch={updateKeyword}
          />
        </Box>
      </Box>

      <Box>
        <DataTable
          columns={languageTaskColumns(isSort, setIsSort)}
          dataList={searchResultForSelectedProjectSimpleAllListItem}
          clickRowData={() => {}}
          noRowsMessage={'검색된 언어 작업이 없습니다.'}
        />
      </Box>

      <Outlet />
    </section>
  );
}

export default LanguageTaskPage;
