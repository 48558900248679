import { css } from '@emotion/react';

const header = css`
  display: flex;
  width: 100%;
  padding: 16px 34px 16px 0;
  font-size: 12px;
  font-weight: 400;
  .header--button--return {
    margin-left: 28px;
    cursor: pointer;
  }
  .header--button--alarm {
    margin-right: 28px;
    img {
      vertical-align: middle;
    }
  }
  .header--dl--userinfo {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    dl {
      background: #fff;
      text-align: center;
      border-radius: 8px;
      border: 1px solid #e5e5e5;
      padding: 3px 10px;
      line-height: 1.25;
      box-shadow: 0 1px 32px 4px #f0edf6;
    }
  }
`;

export const headerWrap = css`
  ${header};
  justify-content: flex-end;
`;

export const retruHeaderWrap = css`
  ${header};
  justify-content: space-between;
`;
