import styled from '@emotion/styled';

export const AsideStyle = styled.aside`
  position: fixed;
  display: flex;
  flex-direction: column;
  padding-top: 36.27px;
  padding-left: 24px;
  padding-bottom: 24px;
  width: 72px;
  /* width: 352px; */

  height: 100%;
  background: linear-gradient(180deg, #291251 0%, #6537ad 100%);
  overflow: hidden;
  box-shadow: 0 4px 32px 4px rgba(44, 53, 88, 0.16);
  transition: width 0.3s ease-in-out, background-position 0.3s ease-in-out;
  z-index: 1100;
  .aside--logo {
    display: flex;
    height: 23.2px;
    width: 200px;
    margin-bottom: 61px;

    .aside--logo--close {
      height: 100%;
      object-fit: none;
      object-position: 0%;
      width: 17px;
    }

    .aside--logo--open {
      width: 200px;
      height: 30px;
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
      img {
        margin-left: -17px;
      }
      p {
        color: #fff;
        font-weight: 700;
        line-height: 1;
      }
    }
  }
  .aside--menu--wrap {
    display: flex;
    flex-direction: column;
    color: #fff;

    a {
      color: #fff;
      img {
        min-width: 24px;
        max-width: 24px;
      }
    }
    .aside--menu {
      position: relative;
      display: flex;
      padding: 12px 0;
      align-items: flex-start;
      justify-content: space-between;
      .aside--ul--main {
        display: flex;
        align-items: center;
        width: 100%;
        transition: all 0.2s ease-out;
        a {
          height: 32px;
          display: flex;
          align-items: center;
          transition: all 0.3s 0.2s;
          span {
            position: absolute;
            left: 40px;
            width: 100px;
            transition: opacity 0.3s ease-in-out;
            opacity: 0;
          }
          &.active {
            filter: drop-shadow(0px 1px 7px #d9ab59);
          }
        }
      }

      :hover {
        .aside--ul--main {
          a {
            filter: drop-shadow(0px 1px 7px #d9ab59);
          }
        }
      }
    }

    .aside--ul--sub {
      width: 173px;
      display: flex;
      flex-direction: column;
      gap: 13px;

      opacity: 0;
      transition: opacity 0.2s;
      li {
        width: 173px;
        display: flex;
        align-items: center;
        height: 32px;
        transition: all 0.2s ease-out;
        span {
        }
        :hover {
          cursor: pointer;
          filter: drop-shadow(0px 1px 7px #d9ab59);
        }
      }
    }
  }

  .illust {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 352px;
    height: 382px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .aside-div-language {
    position: absolute;
    bottom: 24px;
    height: 32px;
    display: flex;
    align-items: center;
    button {
      display: inline-flex;
      align-items: center;
      font-weight: 500;
      span {
        color: #fff;
        margin-left: 8px;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }
    }
  }

  &:hover {
    width: 352px;
    padding-right: 36.27px 0 24px 24px;
    .aside--logo--open {
      opacity: 1;
    }
    .aside--menu--dividing--line {
      width: 100%;

      .sub--menu {
        position: absolute;
        width: 173px;
        left: 179px;
        border-top: 1px solid rgba(243, 243, 244, 0.1);
      }
    }
    .aside--menu {
      .aside--ul--main {
        a {
          span {
            opacity: 1;
          }
        }
      }
    }

    .aside--ul--sub {
      position: absolute;
      left: 155px;
      opacity: 1;
      li {
        span {
        }
      }
    }

    .illust {
      opacity: 1;
    }
    .aside-div-language button span {
      opacity: 1;
    }
  }
`;
