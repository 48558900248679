import { useGetLanguageSpecUser as getLanguageSpecUser } from '@hooks/languageSpec/getLanguageSpecUser';
import { usePostLanguageSpecList } from '@hooks/languageSpec/postList';
import { useEffect, useState } from 'react';
import { usePutLanguageSpec } from '@hooks/languageSpec/putLanguageSpec';
import { useAlert } from '@hooks/modal/useAlert';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

/**
 * @description 언어페어 담당자 변경 /languageSpec
 * @param {string} start
 * @param {string} destination
 * @returns { initData: ILanguageSpec,
 *     languageSpecUserList: IUserListOptions,
 *     mainLanguageLeader: string,
 *     changeMainLanguageLeader: function,
 *     subLanguageLeader: string[],
 *     changeSubLanguageLeader: function,
 *     worker: string[],
 *     changeWorker: function,
 *     putLanguageSpecData: function,
 *     closeModal: function}
 */
export const usePostLanguageSpec = (start?: string, destination?: string) => {
  const { languageSpecList } = usePostLanguageSpecList();
  const { putLanguageSpec } = usePutLanguageSpec();
  const { data } = getLanguageSpecUser();
  const { openHandle: alertHandle } = useAlert();
  const initData = _.filter(languageSpecList, {
    startLanguage: start,
    destinationLanguage: destination
  })[0];
  const navigate = useNavigate();

  const [languageSpecUserList, setLanguageSpecUserList] = useState<{
    mainLanguageLeaderList: {
      value: string;
      label: string;
      option: string;
    }[];
    subLanguageLeaderList: {
      value: string;
      label: string;
      option: string;
    }[];
    workerList: {
      value: string;
      label: string;
      option: string;
    }[];
  }>({
    mainLanguageLeaderList: [],
    subLanguageLeaderList: [],
    workerList: []
  });
  const [mainLanguageLeader, setMainLanguageLeader] = useState<string>('');
  const [subLanguageLeader, setSubLanguageLeader] = useState<string[]>([]);
  const [worker, setWorker] = useState<string[]>([]);

  const changeMainLanguageLeader = (value: string) => {
    setMainLanguageLeader(value);
  };

  const changeSubLanguageLeader = (value: string[]) => {
    setSubLanguageLeader(value);
  };

  const changeWorker = (value: string[]) => {
    setWorker(value);
  };

  const putLanguageSpecData = () => {
    const { startLanguage, destinationLanguage } = initData;

    const payloadData = {
      startLanguage: startLanguage,
      destinationLanguage: destinationLanguage,
      mainLanguageLeaderUserId: mainLanguageLeader,
      subLanguageLeaderUserIdList: subLanguageLeader,
      workUserIdList: worker
    };
    putLanguageSpec(payloadData);
    navigate('/lang', { replace: true });
  };

  const closeModal = () => {
    alertHandle({
      title: '작성 취소',
      text: '입력 중인 내용이 모두 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        navigate('/lang', { replace: true });
      }
    });
  };

  useEffect(() => {
    if (data) {
      setLanguageSpecUserList(
        _.forEach(data, (obj, key) => {
          return (data[key] = _.map(obj, (item) => ({
            value: item.email,
            label: item.name,
            option: item.userId
          })));
        })
      );
    }
    return () => {};
  }, [data]);

  useEffect(() => {
    const { mainLanguageLeaderList } = languageSpecUserList;
    if (initData) {
      if (mainLanguageLeaderList.length > 0) {
        const mainValue = _.find(languageSpecUserList.mainLanguageLeaderList, {
          label: initData?.mainLanguageLeaderUserName
        });
        setMainLanguageLeader(mainValue?.option as string);
        const subValue = _.map(
          _.filter(languageSpecUserList.subLanguageLeaderList, (item) => {
            return _.includes(
              initData?.subLanguageLeaderUserNameList,
              item.label
            );
          }),
          (item) => item.option
        );
        setSubLanguageLeader(subValue);
        const workerValue = _.map(
          _.filter(languageSpecUserList.workerList, (item) => {
            return _.includes(initData?.workUserNameList, item.label);
          }),
          (item) => item.option
        );
        setWorker(workerValue);
      }
    }
    return () => {};
  }, [initData, languageSpecUserList]);

  return {
    initData,
    languageSpecUserList,
    mainLanguageLeader,
    changeMainLanguageLeader,
    subLanguageLeader,
    changeSubLanguageLeader,
    worker,
    changeWorker,
    putLanguageSpecData,
    closeModal
  };
};
