import api from '@api/Api';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { companyKeys } from '@hooks/queryKeyFactory';
import { ICompany, ICompoanyList } from '@common/interfaces';
import { useEffect, useState } from 'react';
import _ from 'lodash';

interface IFilters {
  companyName?: string;
  iconImgUrlStatus?: string;
}

const initialFilters: IFilters = {
  companyName: '',
  iconImgUrlStatus: ''
};
/**
 * /company/list
 * @description 전체 고객사 리스트
 * @returns {
 *     changeCompanyName: string,
 *     setChangeCompanyName: function,
 *     updateCompanyName: function,
 *     iconImgUrlStatus: string,
 *     setIconImgUrlStatus: function,
 *     setIsSort: function,
 *     isSort: { [key: string]: any },
 *     clear: function,
 *     clearFlag: boolean,
 *     companyList: ICompoanyList,
 * }
 */
export const useGetCompanyList = () => {
  //#region state
  const [companyName, setCompanyName] = useState<string>('');
  const [changeCompanyName, setChangeCompanyName] = useState<string>('');
  const [iconImgUrlStatus, setIconImgUrlStatus] = useState<string>('');
  const [clearFlag, setClearFlag] = useState(false);
  const [isSort, setIsSort] = useState<{ [key: string]: any }>({
    code: '',
    isAsc: false
  });
  //#endregion

  const updateCompanyName = () => {
    setCompanyName(changeCompanyName);
  };

  const clear = () => {
    const { companyName, iconImgUrlStatus } = initialFilters;
    setCompanyName(companyName as string);
    setChangeCompanyName(companyName as string);
    setIconImgUrlStatus(iconImgUrlStatus as string);
  };

  //#region api call
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof companyKeys)['list']>
  >): Promise<ICompoanyList> => {
    const [, filter] = queryKey;
    let payload: IFilters = {};
    if (filter.companyName) payload.companyName = filter.companyName;
    if (filter.iconImgUrlStatus)
      payload.iconImgUrlStatus = filter.iconImgUrlStatus;
    return await api.get('/company/list', payload).then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const {
    data: { companyList }
  } = useQuery(
    [...companyKeys.list({ companyName, iconImgUrlStatus })],
    getData,
    {
      initialData: () => ({
        companyList: []
      }),
      select: (data) => ({
        companyList: isSort.code.length
          ? ([
              ..._.orderBy(
                data.companyList,
                [isSort.code],
                [isSort.isAsc ? 'asc' : 'desc']
              )
            ] as ICompany[])
          : data.companyList
      })
    }
  );
  //#endregion

  useEffect(() => {
    const filter = {
      companyName,
      iconImgUrlStatus
    };
    if (JSON.stringify(initialFilters) === JSON.stringify(filter)) {
      setClearFlag(true);
    } else {
      setClearFlag(false);
    }
    return () => {};
  }, [companyName, iconImgUrlStatus]);

  return {
    changeCompanyName,
    setChangeCompanyName,
    updateCompanyName,
    iconImgUrlStatus,
    setIconImgUrlStatus,
    setIsSort,
    isSort,
    clear,
    clearFlag,
    companyList
  };
};
