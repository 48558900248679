import api from '@api/Api';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { usersKeys } from '../queryKeyFactory';

export interface IInitData {
  userId: string;
  email: string;
  name: string;
  slackId: string;
  projectManagerLeader: string;
  projectManager: string;
  languageLeader: string;
  worker: string;
  avatarImgUrl: string;
}

/**
 * /users/:id
 * @description 계정 상세 정보 조회
 * @returns {userData: IInitData}
 */
export const useGetUsers = (id: string) => {
  //#region api call
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof usersKeys)['detail']>
  >): Promise<IInitData> => {
    const [, id] = queryKey;
    return await api.get(`/users/${id}`).then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { data: userData } = useQuery([...usersKeys.detail(id)], getData);
  //#endregion

  return {
    userData
  };
};
