import { useEffect, useState } from 'react';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import api from '@api/Api';
import { usersKeys } from '@hooks/queryKeyFactory';
import { IUser, IUserList } from '@common/interfaces';
import _ from 'lodash';

interface IFilters {
  searchType?: string;
  keyword?: string | '';
  userStatus?: string;
  roleList?: string[];
  avatarImgUrlStatus?: string;
}

const initialFilters: IFilters = {
  searchType: '',
  keyword: '',
  userStatus: '',
  roleList: [''],
  avatarImgUrlStatus: ''
};

/**
 * /users/list
 * @description 유저 리스트
 * @returns {userList: IUserList[],
 *     changeSearchType: string,
 *     setChangeSearchType: React.Dispatch<React.SetStateAction<string>>,
 *     changeKeyword: string,
 *     setChangeKeyword: React.Dispatch<React.SetStateAction<string>>,
 *     updateKeyword: string,
 *     userStatus: string,
 *     setUserStatus,: React.Dispatch<React.SetStateAction<string>>,
 *     roleList: string[],
 *     changeRoleList: (role: string) => void,
 *     avatarImgUrlStatus: string,
 *     setAvatarImgUrlStatus: React.Dispatch<React.SetStateAction<string>>,
 *     setIsSort: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>,
 *     isSort: { [key: string]: any },
 *     clear: () => void,
 *     clearFlag: boolean,}
 */
export const usePostUsersList = () => {
  //#region state
  const [searchType, setSearchType] = useState('');
  const [keyword, setKeyword] = useState('');
  const [changeSearchType, setChangeSearchType] = useState('');
  const [changeKeyword, setChangeKeyword] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [roleList, setRoleList] = useState<string[]>(['']);
  const [avatarImgUrlStatus, setAvatarImgUrlStatus] = useState('');
  const [clearFlag, setClearFlag] = useState(false);
  const [isSort, setIsSort] = useState<{ [key: string]: any }>({
    code: '',
    isAsc: false
  });
  //#endregion

  const changeRoleList = (role: string) => {
    let roles: string[] = [];
    if (role === '') {
      roles = [''];
    } else {
      if (roleList.includes(role)) {
        roles = _.filter(roleList, (el) => el !== role);
      } else {
        roles = [...roleList, role];
      }
      roles = _.filter(roles, (el) => el !== '');
    }
    if (roles.length === 0) roles = [''];
    setRoleList(roles);
  };

  const updateKeyword = () => {
    setSearchType(changeSearchType);
    setKeyword(changeKeyword);
  };

  const clear = () => {
    const { searchType, keyword, userStatus, roleList, avatarImgUrlStatus } =
      initialFilters;
    setSearchType(searchType as string);
    setKeyword(keyword as string);
    setChangeSearchType(searchType as string);
    setChangeKeyword(keyword as string);
    setUserStatus(userStatus as string);
    setRoleList(roleList as string[]);
    setAvatarImgUrlStatus(avatarImgUrlStatus as string);
  };

  //#region api call
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof usersKeys)['list']>
  >): Promise<IUserList> => {
    const [, filter] = queryKey;
    let payload: IFilters = {};
    if (filter.searchType) payload.searchType = filter.searchType;
    if (filter.keyword) payload.keyword = filter.keyword;
    if (filter.userStatus) payload.userStatus = filter.userStatus;
    if (_.includes(filter.roleList, '')) {
      payload.roleList = ['PML', 'PM', 'LL', 'TL'];
    } else {
      payload.roleList = filter.roleList;
    }
    if (filter.avatarImgUrlStatus)
      payload.avatarImgUrlStatus = filter.avatarImgUrlStatus;

    return await api.post('/users/list', payload).then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const {
    data: { userList }
  } = useQuery(
    [
      ...usersKeys.list({
        searchType,
        keyword,
        userStatus,
        roleList,
        avatarImgUrlStatus
      })
    ],
    getData,
    {
      initialData: () => ({
        userList: []
      }),
      select: (data) => ({
        userList: isSort.code.length
          ? ([
              ..._.orderBy(
                data.userList,
                [isSort.code],
                [isSort.isAsc ? 'asc' : 'desc']
              )
            ] as IUser[])
          : data.userList
      })
    }
  );
  //#endregion

  useEffect(() => {
    const filter = {
      searchType,
      keyword,
      userStatus,
      roleList,
      avatarImgUrlStatus
    };
    if (JSON.stringify(initialFilters) === JSON.stringify(filter)) {
      setClearFlag(true);
    } else {
      setClearFlag(false);
    }
    return () => {};
  }, [searchType, keyword, userStatus, roleList, avatarImgUrlStatus]);

  return {
    userList,
    changeSearchType,
    setChangeSearchType,
    changeKeyword,
    setChangeKeyword,
    updateKeyword,
    userStatus,
    setUserStatus,
    roleList,
    changeRoleList,
    avatarImgUrlStatus,
    setAvatarImgUrlStatus,
    setIsSort,
    isSort,
    clear,
    clearFlag
  };
};
