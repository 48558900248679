import { Outlet } from 'react-router-dom';
import Aside from '@layouts/aside/Aside';
import Footer from '@layouts/footer/Footer';
import Header from '@layouts/header/Header';
import { useModalContainer } from '@hooks/modal/useModalContainer';
import { css } from '@emotion/react';

function Layout() {
  const { ModalContainer } = useModalContainer();

  return (
    <>
      <div css={layoutWrap}>
        <Aside />
        <div className="layout--div--contents">
          <div className="layout--div--inner">
            <Header />
            <div className="layout--div--outlet">
              <Outlet />
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <ModalContainer />
    </>
  );
}
export default Layout;

const layoutWrap = css`
  display: flex;
  height: 100%;
  .layout--div--contents {
    width: calc(100% - 72px);
    margin-left: 72px;
    overflow-x: auto;
    background: #fafaff;
    .layout--div--inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 1848px;
      height: 100%;
      margin: 0 auto;
    }
  }
  .layout--div--outlet {
    align-self: stretch;
    flex-grow: 1;
    padding: 0 40px;
  }
`;
