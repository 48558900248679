//lib
import { Box } from '@mui/material';
import { useNavigate, Outlet } from 'react-router-dom';
//etc
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { IconRefresh } from '@resources/icon';
import { AccountWrap } from './Account.style';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import { IconBtnRocket } from '@resources/icon';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import { accountColumns } from '@src/pages/account/Account.columns';
import { TPInputSearchNew } from '@src/units/input/TPInput.searchNew';
import DataTable from '@src/pages/component/DataTable';

//hooks
import { usePostUsersList } from '@hooks/users/postUsersList';

/**
 * 계정관리 페이지
 * route : /account
 * @returns
 */
function AccountPage() {
  const navigate = useNavigate();

  const {
    userList,
    changeSearchType,
    setChangeSearchType,
    changeKeyword,
    setChangeKeyword,
    updateKeyword,
    userStatus,
    setUserStatus,
    roleList,
    changeRoleList,
    avatarImgUrlStatus,
    setAvatarImgUrlStatus,
    setIsSort,
    isSort,
    clear,
    clearFlag
  } = usePostUsersList();
  //#region store function

  return (
    <section css={AccountWrap}>
      <Box mb={'26px'} display={'flex'}>
        <Box display={'flex'} flexDirection={'column'} flexGrow={1} ml={2}>
          <dl className="project--dl--info">
            <TPHeadlineBasic type="h2" textAlign="left">
              보라캣 계정 관리
            </TPHeadlineBasic>
          </dl>
        </Box>
      </Box>

      <Box mb={3} display={'flex'} justifyContent={'space-between'}>
        <Box display={'inline-flex'} gap={'8px'}>
          <TPSelectCheck
            label=""
            useOptionLabel
            optionList={[
              { code: '', label: '전체' },
              { code: 'EMAIL', label: '이메일' },
              { code: 'NAME', label: '사용자명' }
            ]}
            selectOptions={[changeSearchType]}
            changeSelect={setChangeSearchType}
          />
          {changeSearchType === '' ? null : (
            <TPInputSearchNew
              value={changeKeyword}
              placeholder="검색"
              onChange={(e) => setChangeKeyword(e.target.value)}
              clickSearch={updateKeyword}
            />
          )}
          <TPSelectCheck
            label="사용여부"
            useOptionLabel
            optionList={[
              { code: '', label: '전체' },
              { code: 'ACTIVE', label: '사용' },
              { code: 'INACTIVE', label: '미사용' }
            ]}
            selectOptions={[userStatus]}
            changeSelect={setUserStatus}
          />
          <TPSelectCheck
            label="권한"
            useOptionLabel
            optionList={[
              { code: '', label: '전체' },
              { code: 'PML', label: 'PM리더' },
              { code: 'PM', label: 'PM' },
              { code: 'LL', label: '언어리더' },
              { code: 'TL', label: '작업자' }
            ]}
            selectOptions={roleList}
            changeSelect={changeRoleList}
          />
          <TPSelectCheck
            label="프로필사진"
            useOptionLabel
            optionList={[
              { code: '', label: '전체' },
              { code: 'true', label: '등록' },
              { code: 'false', label: '미등록' }
            ]}
            selectOptions={[avatarImgUrlStatus]}
            changeSelect={setAvatarImgUrlStatus}
          />

          {clearFlag ? null : (
            <button className="main--button--refresh" onClick={clear}>
              <IconRefresh />
              <span>초기화</span>
            </button>
          )}
        </Box>

        <TPButtonBasic
          className=""
          label="계정 등록"
          onClick={() => {
            navigate('/account/register');
          }}
          size="medium"
          startIcon={<IconBtnRocket />}
          variant="contained"
        />
      </Box>

      <Box>
        <DataTable
          columns={accountColumns(isSort, setIsSort)}
          dataList={userList}
          clickRowData={() => {}}
          noRowsMessage={'등록된 계정이 없습니다.'}
        />
      </Box>

      <Outlet />
    </section>
  );
}

export default AccountPage;
