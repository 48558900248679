import api from '@api/Api';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { projectKeys } from '../queryKeyFactory';

export interface IInitData {
  projectId: string;
  projectTitle: string;
  projectKey: string;
  companyName: string;
  contractType: string;
  startDateTime: string;
  endDateTime: string;
  machineTranslationType: string;
  prompt: string;
}
/**
 * /projectManage/:id
 * @description 프로젝트 상세 조회
 * @param {string} id
 * @returns {projectData: IInitData}
 */
export const useGetProject = (id: string) => {
  //#region api call
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof projectKeys)['detail']>
  >): Promise<IInitData> => {
    const [, id] = queryKey;
    return await api.get(`/projectManage/${id}`).then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { data: projectData } = useQuery([...projectKeys.detail(id)], getData);
  //#endregion

  return {
    projectData
  };
};
