import { createTheme } from '@mui/material/styles/';

export const TPButtonTheme = createTheme({
  typography: { fontFamily: 'Spoqa Han Sans Neo', fontSize: 16 },
  components: {
    MuiFab: {
      styleOverrides: {
        root: {
          background: '#fff',
          border: '1px solid #D5D3E4',
          boxShadow: 'none',
          verticalAlign: 'baseline',
          '& svg': {
            width: 24,
            height: 24
          },
          '&:hover': {
            background: '#fff',
            border: '1px solid #9B51E0',
            '& svg *': {
              fill: '#9B51E0'
            }
          },
          '&:disabled': {
            background: '#F6F6FA',
            border: '1px solid #D5D3E4',
            '& svg *': {
              fill: '#9A9CB5'
            }
          },
          '&.bordernone': { background: 'transparent', border: 'none' },
          '&.contentWrap': {
            width: '100%',
            padding: '0 16px 0 20px',
            borderRadius: '12px',
            '>span': {
              marginRight: '14px',
              fontSize: '14px',
              fontWeight: '400'
            }
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          background: '#fff',
          borderRadius: '40px',
          lineHeight: '160%',
          fontWeight: 400,
          color: '#2C3558',
          '&:disabled': {
            color: '#D5D3E4'
          }
        },
        startIcon: {
          marginLeft: 0,
          marginRight: 4,
          '&>svg:nth-of-type(1)': {
            width: '100%'
          }
        },
        sizeSmall: {
          minHeight: 32,
          fontSize: '0.75rem',
          padding: '0 16px',
          '& .MuiLoadingButton-loadingIndicatorStart': {
            left: 5
          },
          '& .MuiLoadingButton-startIconLoadingStart + .MuiLoadingButton-loadingIndicatorStart':
            {
              left: 15
            },
          '&.MuiButton-fullWidth span.MuiLoadingButton-startIconLoadingStart': {
            width: 0,
            marginRight: 4
          },
          '&.MuiButton-fullWidth .MuiLoadingButton-loadingIndicatorStart': {
            left: 0,
            marginRight: 3
          }
        },
        iconSizeSmall: {
          width: 16,
          height: 16
        },
        sizeMedium: {
          minHeight: 40,
          fontSize: '0.875rem',
          padding: '0 24px',
          '& .MuiLoadingButton-loadingIndicatorStart': {
            left: 6
          },
          '& .MuiLoadingButton-startIconLoadingStart + .MuiLoadingButton-loadingIndicatorStart':
            {
              left: 24
            },
          '&.MuiButton-fullWidth span.MuiLoadingButton-startIconLoadingStart': {
            width: 0,
            marginRight: 4
          },
          '&.MuiButton-fullWidth .MuiLoadingButton-loadingIndicatorStart': {
            left: 0,
            marginRight: 3
          }
        },
        iconSizeMedium: {
          width: 18,
          height: 18
        },
        sizeLarge: {
          minHeight: 56,
          fontSize: '1rem',
          padding: '0 24px',
          '& .MuiLoadingButton-loadingIndicatorStart': {
            left: 5
          },
          '& .MuiLoadingButton-startIconLoadingStart + .MuiLoadingButton-loadingIndicatorStart':
            {
              left: 28
            },
          '&.MuiButton-fullWidth span.MuiLoadingButton-startIconLoadingStart': {
            width: 0,
            marginRight: 4
          },
          '&.MuiButton-fullWidth .MuiLoadingButton-loadingIndicatorStart': {
            left: 0,
            marginRight: 7
          }
        },
        iconSizeLarge: {
          width: 24,
          height: 24
        },
        text: {
          color: '#2C3558',
          '&:hover': {
            background: '#fff'
          },
          '&:disabled': {
            background: 'transparent'
          }
        },
        contained: {
          fontWeight: 500,
          color: '#fff',
          background: 'linear-gradient(270deg, #AA51E0 0%, #9B51E0 100%)',
          boxShadow: '0px 4px 6px rgba(169, 81, 224, 0.24)',
          '&:hover': {
            background: 'linear-gradient(270deg, #D463FC 0%, #B655F2 100%)',
            boxShadow: '0px 4px 6px rgba(169, 81, 224, 0.24)'
          },
          '&:disabled': {
            color: '#fff',
            background: '#D5D3E4'
          }
        },
        containedSizeSmall: {
          fontWeight: 400,
          boxShadow: '0px 4px 6px rgba(169, 81, 224, 0.16)',
          '&:hover': {
            boxShadow: '0px 4px 6px rgba(169, 81, 224, 0.16)'
          }
        },
        outlined: {
          border: '1px solid #D5D3E4',
          '&:hover': {
            color: '#9B51E0',
            background: '#fff',
            border: '1px solid #9B51E0'
          }
        },
        outlinedSizeSmall: {
          boxShadow: '0px 1px 6px rgba(44, 53, 88, 0.08)',
          '&:disabled': {
            boxShadow: 'none'
          }
        }
      }
    }
  }
});

export const TPButtonTabTheme = createTheme({
  typography: { fontFamily: 'Spoqa Han Sans Neo', fontSize: 16 },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '24px',
          lineHeight: '160%',
          fontWeight: 400,
          color: '#2C3558',
          background: '#fff',
          boxShadow: 'none',
          minHeight: 48,
          border: '1px solid #EEEDF4',
          '&.active': {},
          '&:hover, &.active': {
            background: '#2C3558',
            borderColor: '#2C3558',
            color: '#fff',
            boxShadow: '0px 4px 8px rgba(44, 53, 88, 0.24)'
          },
          '& .inner--count': {
            fontSize: '1rem',
            fontWeight: 700,
            marginLeft: '4px'
          }
        }
      }
    }
  }
});
