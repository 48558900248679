import styled from '@emotion/styled';
import { createTheme } from '@mui/material/styles/';

export const TPAvatarGroupTheme = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  div {
    margin-left: -10px;
    border: 2px solid #fff;
    .inner--text {
      width: 100%;
      height: 100%;
    }
  }
`;

export const TPAvatarTheme = createTheme({
  typography: { fontFamily: 'Spoqa Han Sans Neo', fontSize: 16 },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          '&.cursor-pointer': {
            cursor: 'pointer'
          },
          '&.xsmall': {
            width: 24,
            height: 24,
            fontSize: '0.75rem',
            '& .inner--text': {
              fontSize: '0.5rem',
              lineHeight: '1.315rem'
            }
          },
          '&.small': {
            width: 32,
            height: 32,
            '& .inner--text': {
              lineHeight: '1.7rem'
            }
          },
          '&.medium': {
            width: 48,
            height: 48,
            '& .inner--text': {
              lineHeight: '2.7rem'
            }
          },
          '&.large': {
            width: 72,
            height: 72,
            '& .inner--text': {
              fontSize: '1.25rem',
              lineHeight: '4.5rem'
            },
            '& svg': {
              width: 28,
              height: 28
            }
          },
          '&.xlarge': {
            width: 200,
            height: 200,
            '& .inner--text': {
              fontSize: '1.25rem',
              lineHeight: '4.5rem'
            }
          },
          '& .inner--text': {
            width: '100%',
            height: '100%',
            background: '#2C3558',
            borderRadius: '50%',
            color: '#fff',
            textAlign: 'center',
            fontWeight: 500,
            fontSize: '0.75rem',
            verticalAlign: 'middle'
          },
          '& svg': {
            width: 18,
            height: 18
          }
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          '& .MuiBadge-badge': {
            backgroundColor: '#E6311A',
            color: '#E6311A',
            boxShadow: `0 0 0 2px #fff`,
            borderRadius: '50%',
            borderWidth: 2,
            '&::after': {
              position: 'absolute',
              top: -1,
              left: -1,
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              animation: 'ripple 1.2s infinite ease-in-out',
              border: '1px solid currentColor',
              content: '""'
            }
          },
          '&.xsmall': {
            width: 24,
            height: 24,
            '& .MuiBadge-badge': {
              width: 4,
              minWidth: 4,
              height: 4
            }
          },
          '&.small': {
            width: 32,
            height: 32,
            '& .MuiBadge-badge': {
              width: 6,
              minWidth: 6,
              height: 6
            }
          },
          '&.medium': {
            width: 48,
            height: 48,
            '& .MuiBadge-badge': {
              width: 11,
              height: 11
            }
          },
          '&.large': {
            width: 72,
            height: 72,
            '& .MuiBadge-badge': {
              width: 16,
              height: 16
            }
          },
          '@keyframes ripple': {
            '0%': {
              transform: 'scale(.8)',
              opacity: 1
            },
            '100%': {
              transform: 'scale(2.4)',
              opacity: 0
            }
          }
        }
      }
    }
  }
});
