import { useQuery } from '@tanstack/react-query';
import { authKeys } from '@hooks/queryKeyFactory';
import api from '@api/Api';

export const useMyInfo = () => {
  const getMyInfoData = async () => {
    if (
      !localStorage.getItem('accessToken') &&
      localStorage.getItem('accessToken') === null
    ) {
      return null;
    }
    return (await api.get(`/myInfo`)).data;
  };

  const {
    data: myInfo,
    refetch: getMyInfo,
    isSuccess,
    error
  } = useQuery([...authKeys.myInfo], getMyInfoData);

  return { myInfo, getMyInfo, isSuccess, error };
};
