import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@hooks/modal/useToast';
import { useAlert } from '@hooks/modal/useAlert';
import { useNavigate as Navigate } from 'react-router-dom';

/**
 * @description 유저 계정 삭제 /users/delete
 * @returns {deleteUser: function}
 */
export const usePutDelete = () => {
  const queryClient = useQueryClient();
  const { openHandle: openToast } = useToast();
  const { openHandle } = useAlert();
  const navigate = Navigate();
  //#region api call
  const getData = async (status: { userId: string }) => {
    return await api.put('/users/delete', status).then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { mutate: deleteUser } = useMutation({
    mutationFn: getData,
    onSuccess: () => {
      queryClient.invalidateQueries(['/users/list']);
      openToast({
        text: `계정이 삭제 되었습니다.`,
        severity: 'success'
      });
    },
    onError: (error: any) => {
      if (error.errors && error.errors.length > 0) {
        const errors = JSON.parse(error.errors[0]);
        const { startLanguage, destinationLanguage } = errors[0];
        openHandle({
          text: `메인 언어리더는 삭제할수 없습니다.<br/>메인 언어리더를 변경 후 삭제해주세요.`,
          ok: () => {
            navigate(
              `/lang/modify?start=${startLanguage}&destination=${destinationLanguage}`,
              {
                replace: true
              }
            );
          }
        });
      } else {
        openToast({
          text: `계정 삭제에 실패하였습니다.`,
          severity: 'error'
        });
      }
    }
  });
  //#endregion

  return {
    deleteUser
  };
};
