import api from '@api/Api';

import { useMemo } from 'react';
import { languageTaskKeys } from '../queryKeyFactory';
import { useQuery } from '@tanstack/react-query';

export function useGetProjectList() {
  // 프로젝트 리스트 GET /project/simpleAllList
  const { data: projectSimpleAllList } = useQuery(
    languageTaskKeys.projectSimpleAllList,
    async function () {
      return await api
        .get(languageTaskKeys.projectSimpleAllList[0])
        .then((res) => res.data);
    },
    {
      initialData: [],
      suspense: false
    }
  );

  // 프로젝트 선택박스옵션 리스트
  const selectElementOptionListForProjectSimpleAllList = useMemo(() => {
    if (Array.isArray(projectSimpleAllList)) {
      return projectSimpleAllList.map((e: any) => ({
        code: e.projectId.toString(),
        label: e.projectTitle
      }));
    } else {
      return [];
    }
  }, [projectSimpleAllList]);

  return {
    selectElementOptionListForProjectSimpleAllList
  };
}
