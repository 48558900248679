/**
 * @description 전역 변수로 사용되는 쿼리키
 * @property {string[]} authentication 인증 관련 쿼리키
 * @property {string[]} alert 알림 관련 쿼리키
 * @property {string[]} toast 토스트 관련 쿼리키
 * @property {string[]} rangeCalendar 범위 달력 관련 쿼리키
 * @property {string[]} singleCalendar 단일 달력 관련 쿼리키
 */
export const globalKeys: {
  [key: string]: readonly string[];
} = {
  authentication: ['authentication'] as const,
  alert: ['alert'] as const,
  toast: ['toast'] as const,
  rangeCalendar: ['rangeCalendar'] as const,
  singleCalendar: ['singleCalendar'] as const
};
