import { ThemeProvider } from '@mui/material/styles';
import { IconPlus } from '@resources/icon';
import { Fab } from '@mui/material';
import { TPButtonTheme } from './TPButton.Theme';
import { ReactElement, MouseEvent } from 'react';

/**
 * @description Button Action 컴포넌트
 * @param props
 */
export const TPButtonAction = (props: {
  size?: 'small' | 'medium' | 'large' | undefined;
  disabled?: boolean;
  onClick?: (e: MouseEvent) => void;
  icon?: ReactElement;
  outline?: boolean;
}) => {
  const { icon, outline } = props;
  const propsOverride = {
    ...props,
    icon: undefined,
    outline: undefined
  };
  return (
    <ThemeProvider theme={TPButtonTheme}>
      <Fab className={outline ? '' : 'bordernone'} {...propsOverride}>
        {icon ? icon : <IconPlus />}
      </Fab>
    </ThemeProvider>
  );
};
