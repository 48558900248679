import { loginWrap } from '@pages/login/Login.style';
import Logo from '@images/login-boracat-logo.svg';
import Illust from '@images/login-boracat-illust.svg';
import { useModalContainer } from '@hooks/modal/useModalContainer';
import GoogleLoginBtn from '@pages/login/GoogleLoginBtn.comp';
import { useEffect, useRef } from 'react';

function LoginPage() {
  const { ModalContainer } = useModalContainer();
  const timeoutRef = useRef<number>(NaN);

  useEffect(() => {
    console.debug('로그인 캐시 삭제.');
    timeoutRef.current = window.setTimeout(() => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessTokenExpiresIn');
      localStorage.removeItem('refreshTokenExpiresIn');
    }, 700);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <>
      <section css={loginWrap}>
        <div className="loginBox">
          <div className="loginBoxRight">
            <div className="illust">
              <img src={Illust} alt="boracat" />
            </div>
            <h1 className="loginLogo">
              <img src={Logo} alt="boracat" />
            </h1>
            <h2 className="loginTitle">로그인</h2>
            <div>
              <GoogleLoginBtn />
            </div>

            <p className="loginPrivacyInfo">
              어드민 관련 문의는{' '}
              <strong className="loginPrivacyBtn">보라캣개발팀</strong>에 문의해
              주세요.
            </p>
          </div>
        </div>
      </section>
      <ModalContainer />
    </>
  );
}

export default LoginPage;
