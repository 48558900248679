import api from '@api/Api';
import { useMutation } from '@tanstack/react-query';

/**
 * /users/emailValid
 * @description 이메일 중복 체크
 * @returns {emailValid: function, isEmailValid: boolean, isEmailLoading: boolean}
 */
export const useGetEmailValid = () => {
  //#region api call
  const getData = async (email: string) => {
    return await api
      .get('/users/emailValid', { email })
      .then((res) => res.data);
  };
  //#endregion

  //#region useMutation define
  const {
    mutate: emailValid,
    isError: isEmailValid,
    isLoading: isEmailLoading
  } = useMutation({
    mutationFn: getData
  });
  //#endregion

  return {
    emailValid,
    isEmailValid,
    isEmailLoading
  };
};
