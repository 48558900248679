import React from 'react';
import { IconDetail } from '@src/resources/icon';
import { TPButtonAction } from '@src/units/button/TPButton.Action';
import { TPPopoverMenu } from '@src/units/popover/TPPopover.Menu';
import { format } from 'date-fns';
import ColumnHeadComp from '@src/pages/component/ColumnHead.Comp';
import { getLanguage } from '@common/Code';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import _ from 'lodash';
import { useNavigate as Navigate } from 'react-router-dom';

export const languagePairColumns = (isSort: any, setIsSort: any) =>
  [
    {
      code: 'orderNumber',
      value: '순번',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'languagePair',
      value: '언어페어',
      width: 300,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return (
          <>
            {`${getLanguage([rowData.startLanguage])[0].label}`}
            <ArrowRightAltIcon />
            {`${getLanguage([rowData.destinationLanguage])[0].label}`}
          </>
        );
      }
    },
    {
      code: 'mainLanguageLeaderUserName',
      value: '메인언어리더',
      width: 300,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType={'unable'}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'subLanguageLeaderUserNameList',
      value: '서브 언어리더',
      width: 380,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType={'unable'}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return rowData.subLanguageLeaderUserNameList.length > 0
          ? _.join(rowData.subLanguageLeaderUserNameList, ', ')
          : '-';
      }
    },
    {
      code: 'workUserNameList',
      value: '작업자',
      width: 380,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType={'unable'}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return rowData.workUserNameList.length > 0
          ? _.join(rowData.workUserNameList, ', ')
          : '-';
      }
    },
    {
      code: 'updatedDateTime',
      value: '마지막 업데이트',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        let lastUpdate = `${format(
          new Date(rowData.updatedDateTime),
          'yyyy-MM-dd'
        )}`;
        if (rowData.lastUpdateUserName) {
          lastUpdate += `<br/><strong>${rowData.lastUpdateUserName}</strong>`;
        }
        return <p dangerouslySetInnerHTML={{ __html: lastUpdate }}></p>;
      }
    },
    {
      code: 'option',
      value: '',
      width: 24,
      renderRowData: (data: any, rowData: any) => {
        const navigate = Navigate();
        let menusArr: {
          clickMenu: () => void;
          label: string;
          to?: string;
          state?: {};
        }[] = [
          {
            clickMenu: () => {
              navigate(
                `/lang/modify?start=${rowData.startLanguage}&destination=${rowData.destinationLanguage}`
              );
            },
            label: '담당자 변경'
          }
        ];

        return menusArr.length > 0 ? (
          <TPPopoverMenu
            popoverMenus={menusArr}
            clickPopoverButton={(e: any) => {
              e.stopPropagation();
            }}
          >
            <TPButtonAction icon={<IconDetail />} size="small" />
          </TPPopoverMenu>
        ) : null;
      }
    }
  ] as const;
