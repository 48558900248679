import api from '@api/Api';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { companyKeys } from '../queryKeyFactory';

export interface IInitData {
  companyId: string;
  companyName: string;
  iconImgUrl: string;
}
/**
 * /company/:id
 * @description 고객사 상세 조회
 * @param {string} id
 * @returns {companyData: IInitData}
 */
export const useGetCompany = (id: string) => {
  //#region api call
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof companyKeys)['detail']>
  >): Promise<IInitData> => {
    const [, id] = queryKey;
    return await api.get(`/company/${id}`).then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { data: companyData } = useQuery([...companyKeys.detail(id)], getData);
  //#endregion

  return {
    companyData
  };
};
