import { css } from '@emotion/react';

export const AccountWrap = css`
  h2 {
    max-width: 40%;
    word-break: break-all;
    word-wrap: break-word;
  }
  .main--button--refresh {
    margin-left: 24px;
    font-size: 0.875rem;
    margin-right: 20px;

    svg {
      width: 18px;
      height: 18px;
      vertical-align: top;
    }
    span {
      text-decoration: underline;
      margin-left: 4px;
    }
  }
  .project--dl--info {
    display: flex;
    gap: 24px;
    align-items: center;
    font-weight: 500;
    line-height: 1;
    > div {
      display: flex;
      align-items: center;
      dt {
        font-size: 0.75rem;
        margin-right: 8px;
        color: #9a9cb5;
      }
      dd {
        font-size: 0.875rem;
      }
      .dDay {
        margin-left: 8px;
      }
    }
  }
`;
