import React, { ComponentType } from 'react';
import Logo from '@images/bi_ver2.png';
import { ReactComponent as Boracat } from '@resources/images/boracat_side_illust.svg';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import GTranslateSharpIcon from '@mui/icons-material/GTranslateSharp';
import AccountTreeSharpIcon from '@mui/icons-material/AccountTreeSharp';
import SupportAgentSharpIcon from '@mui/icons-material/SupportAgentSharp';
import { SvgIconProps } from '@mui/material';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import type { LinkProps } from 'react-router-dom';
import { AsideStyle } from './Aside.style';
import { ISideMenu } from '@common/interfaces';
import _ from 'lodash';

function Aside() {
  const sideMenuAccount = {
    menu: { name: '계정관리', to: '/account' },
    subMenu: [],
    icon: AccountCircleSharpIcon
  };

  const sideMenuLanguagePair = {
    menu: { name: '언어관리', to: '/lang' },
    subMenu: [],
    icon: GTranslateSharpIcon
  };

  const sideMenuProject = {
    menu: { name: '프로젝트 관리', to: '/project' },
    subMenu: [],
    icon: AccountTreeSharpIcon
  };

  const sideMenuCompany = {
    menu: { name: '고객사 관리', to: '/company' },
    subMenu: [],
    icon: SupportAgentSharpIcon
  };

  // const sideMenuFileManagement = {
  //   menu: { name: '파일 관리', to: '/fileManagement' },
  //   subMenu: [],
  //   icon: SupportAgentSharpIcon
  // }
  const sideMenuLanguageTask = {
    menu: { name: '언어작업 목록', to: '/languagetask' },
    subMenu: [],
    icon: AccountTreeSharpIcon
  };

  return (
    <AsideStyle>
      <div className="aside--logo">
        <img className="aside--logo--close" src={Logo} alt="boracat" />
        <div className="aside--logo--open">
          <img src={Logo} alt="boracat" /> <p>Admin</p>
        </div>
      </div>

      <ul className="aside--menu--wrap">
        <SideMenuMaker sideMenu={sideMenuAccount}></SideMenuMaker>
        <SideMenuMaker sideMenu={sideMenuLanguagePair}></SideMenuMaker>
        <SideMenuMaker sideMenu={sideMenuProject}></SideMenuMaker>
        <SideMenuMaker sideMenu={sideMenuCompany}></SideMenuMaker>
        {/* <SideMenuMaker sideMenu={sideMenuFileManagement}></SideMenuMaker> */}
        <SideMenuMaker sideMenu={sideMenuLanguageTask}></SideMenuMaker>
      </ul>

      <Boracat className="illust" />
    </AsideStyle>
  );
}
export default Aside;

function CustomLink({ children, to, ...props }: LinkProps) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link className={`${match ? 'active' : ''}`} to={to} {...props}>
      {children}
    </Link>
  );
}

interface ISideMenuMaker {
  sideMenu: {
    menu: Partial<ISideMenu>;
    subMenu?: ISideMenu[] | null;
    icon: string | ComponentType<SvgIconProps>;
  };
}
const SideMenuMaker = ({ sideMenu }: ISideMenuMaker) => {
  const { menu, subMenu, icon } = sideMenu;
  const Icon = icon;
  if (!subMenu) return <></>;
  const height = subMenu.length
    ? `${60 + 40 * (subMenu.length - 1)}px`
    : '60px';
  return (
    <ul className="aside--menu" style={{ height: height }}>
      <li className="aside--ul--main">
        <CustomLink to={menu.to!}>
          {typeof icon === 'string' ? <img src={icon} alt="" /> : <Icon />}
          <span>{menu.name}</span>
        </CustomLink>
      </li>
      <ul className="aside--ul--sub" style={{ height: height }}>
        {_.map(_.sortBy(subMenu, 'idx'), (el) => {
          return (
            <li key={el.to}>
              <CustomLink to={el.to}>
                <span>{el.name}</span>
              </CustomLink>
            </li>
          );
        })}
      </ul>
    </ul>
  );
};
