import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@hooks/modal/useToast';

/**
 * @description 유저 상태 변경 /users/status
 * @returns {changeStatus: function}
 */
export const usePutStatus = () => {
  const queryClient = useQueryClient();
  const { openHandle } = useToast();
  //#region api call
  const getData = async (status: {
    userId: string;
    userStatus: 'ACTIVE' | 'INACTIVE';
  }) => {
    return await api.put('/users/status', status).then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { mutate: changeStatus } = useMutation({
    mutationFn: getData,
    onSuccess: (data, variables) => {
      const { userStatus } = variables;
      queryClient.invalidateQueries(['/users/list']);
      openHandle({
        text: `계정이 ${
          userStatus === 'ACTIVE' ? '활성화' : '비활성화'
        }되었습니다.`,
        severity: 'success'
      });
    },
    onError: () => {
      openHandle({
        text: `계정 상태 변경에 실패하였습니다.`,
        severity: 'error'
      });
    }
  });
  //#endregion

  return {
    changeStatus
  };
};
