import React from 'react';
import { IconDetail } from '@src/resources/icon';
import { TPButtonAction } from '@src/units/button/TPButton.Action';
import { TPPopoverMenu } from '@src/units/popover/TPPopover.Menu';
import { format } from 'date-fns';
import ColumnHeadComp from '@src/pages/component/ColumnHead.Comp';
import { useAlert as alert } from '@hooks/modal/useAlert';
import { TPAvatarBasic } from '@units/avatar/TPAvatar.Basic';
import { TPTooltipBasic } from '@units/tooltip/TPTooltip.Basic';
import { getContractType, getStatus } from '@common/Code';
import { usePutExposureStatus as putExposureStatus } from '@hooks/project/putExposureStatus';
import { useNavigate as Navigate } from 'react-router-dom';

export const projectColumns = (isSort: any, setIsSort: any) =>
  [
    {
      code: 'projectId',
      value: '순번',
      width: 80,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'projectStatus',
      value: '상태',
      width: 80,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return getStatus(rowData.projectStatus)[0].label;
      }
    },
    {
      code: 'projectKey',
      value: '키',
      width: 100,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'companyName',
      value: '고객사',
      width: 150,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'projectTitle',
      value: '프로젝트',
      width: 300,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: 'string'
    },
    {
      code: 'mainProjectManager',
      value: '메인 PM',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType={'unable'}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return (
          <TPTooltipBasic title={rowData.mainProjectManager.name}>
            {rowData.mainProjectManager.avatarImgUrl ? (
              <TPAvatarBasic
                src={rowData.mainProjectManager.avatarImgUrl}
                alt={rowData.mainProjectManager.name}
              />
            ) : (
              <span>-</span>
            )}
          </TPTooltipBasic>
        );
      }
    },
    {
      code: 'subProjectManager',
      value: '서브 PM',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType={'unable'}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return (
          <TPTooltipBasic title={rowData.subProjectManager.name}>
            {rowData.subProjectManager.avatarImgUrl ? (
              <TPAvatarBasic
                src={rowData.subProjectManager.avatarImgUrl}
                alt={rowData.subProjectManager.name}
              />
            ) : (
              <span>-</span>
            )}
          </TPTooltipBasic>
        );
      }
    },
    {
      code: 'startDateTime',
      value: '계약 시작일',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return format(new Date(rowData.startDateTime), 'yyyy-MM-dd');
      }
    },
    {
      code: 'endDateTime',
      value: '계약 종료일',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return format(new Date(rowData.endDateTime), 'yyyy-MM-dd');
      }
    },
    {
      code: 'contractType',
      value: '견적 유형',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return getContractType(rowData.contractType)[0].label;
      }
    },
    {
      code: 'exposureStatus',
      value: '노출 여부',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
            sortType={'unable'}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        return rowData.exposureStatus ? 'Y' : 'N';
      }
    },
    {
      code: 'updatedDateTime',
      value: '마지막 업데이트',
      width: 120,
      renderHeader(headerData: any) {
        return (
          <ColumnHeadComp
            title={headerData.value}
            field={headerData.code}
            isSort={isSort}
            setIsSort={setIsSort}
          />
        );
      },
      renderRowData: (data: any, rowData: any) => {
        let lastUpdate = `${format(
          new Date(rowData.updatedDateTime),
          'yyyy-MM-dd'
        )}`;
        if (rowData.lastUpdateUserName) {
          lastUpdate += `<br/><strong>${rowData.lastUpdateUserName}</strong>`;
        }
        return <p dangerouslySetInnerHTML={{ __html: lastUpdate }}></p>;
      }
    },
    {
      code: 'option',
      value: '',
      width: 24,
      renderRowData: (data: any, rowData: any) => {
        const { openHandle: openAlert } = alert();
        const { changeStatus } = putExposureStatus();
        const navigate = Navigate();
        let menusArr: {
          clickMenu: () => void;
          label: string;
          to?: string;
          state?: {};
        }[] = [
          {
            clickMenu: () => {
              navigate(`/project/modify/${rowData.projectId}`);
            },
            label: '수정하기'
          },
          {
            clickMenu: () => {
              openAlert({
                title: '프로젝트 미노출',
                text: `${rowData.companyName}-${rowData.projectTitle}<br/>프로젝트를 보라캣에서<br/>노출하지 않으시겠습니까?`,
                cancelLabel: '취소',
                ok: () => {
                  changeStatus({
                    projectId: rowData.projectId,
                    exposureStatus: false,
                    info: {
                      companyName: rowData.companyName,
                      projectTitle: rowData.projectTitle
                    }
                  });
                }
              });
            },
            label: '미노출'
          }
        ];

        if (!rowData.exposureStatus) {
          menusArr = [
            {
              clickMenu: () => {
                navigate(`/project/modify/${rowData.projectId}`);
              },
              label: '수정하기'
            },
            {
              clickMenu: () => {
                openAlert({
                  title: '프로젝트 미노출',
                  text: `${rowData.companyName}-${rowData.projectTitle}<br/>프로젝트를 보라캣에서<br/>노출하시겠습니까?`,
                  cancelLabel: '취소',
                  ok: () => {
                    changeStatus({
                      projectId: rowData.projectId,
                      exposureStatus: true,
                      info: {
                        companyName: rowData.companyName,
                        projectTitle: rowData.projectTitle
                      }
                    });
                  }
                });
              },
              label: '노출'
            }
          ];
        }

        return menusArr.length > 0 ? (
          <TPPopoverMenu
            popoverMenus={menusArr}
            clickPopoverButton={(e: any) => {
              e.stopPropagation();
            }}
          >
            <TPButtonAction icon={<IconDetail />} size="small" />
          </TPPopoverMenu>
        ) : null;
      }
    }
  ] as const;
