import api from '@api/Api';
import { useQuery } from '@tanstack/react-query';
import { languageSpecKeys } from '../queryKeyFactory';

/**
 * /languageSpec/languageSpecUser
 * @description 언어페어 리더, 작업자 전체 조회
 * @returns { data: { mainLanguageLeaderList: [],
 *       subLanguageLeaderList: [],
 *       workerList: [] }
 */
export const useGetLanguageSpecUser = () => {
  //#region api call
  const getData = async () => {
    return await api
      .get('/languageSpec/languageSpecUser')
      .then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { data } = useQuery([...languageSpecKeys.languageSpecUser], getData, {
    initialData: () => ({
      mainLanguageLeaderList: [],
      subLanguageLeaderList: [],
      workerList: []
    })
  });
  //#endregion

  return {
    data
  };
};
