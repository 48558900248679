import React, { useRef } from 'react';
import {
  DataTableColumnHeadStyle,
  DataTableRowDataStyle,
  DataTableStyle,
  NoMessageContainer
} from './DataTable.style';
import {
  IUser,
  ILanguageSpec,
  IProject,
  ICompany,
  ILanguageTask
} from '@common/interfaces';
import _ from 'lodash';

/**
 * @description 테이블 column head 컴포넌트
 * @param props
 */
const DataTableColumnHead = (props: {
  columns: readonly {
    code: string;
    value: any;
    width: number;
    renderHeader?: any;
    renderRowData?: any;
  }[];
  gap?: number;
}) => {
  const { columns, gap } = props;
  const codes = _.map(columns, 'code');
  const isColspan =
    _.includes(codes, 'projectManagerLeader') &&
    _.includes(codes, 'projectManager') &&
    _.includes(codes, 'languageLeader') &&
    _.includes(codes, 'worker');

  const ColspanHead = () => {
    return (
      <div className="head colspan" style={{ width: '544px' }}>
        <div className={'head--top'}>권한</div>
        <div className={'head--bottom'}>
          {_.map(columns, (el, index) => {
            const width = el.width > 0 ? el.width : null;
            const style = {
              width: `${width}px`
            };
            if (
              el.code === 'projectManagerLeader' ||
              el.code === 'projectManager' ||
              el.code === 'languageLeader' ||
              el.code === 'worker'
            ) {
              return (
                <div className="head" key={`per${index}`} style={style}>
                  <span>{el.value}</span>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  };

  return (
    <DataTableColumnHeadStyle gap={gap}>
      {_.map(columns, (el, index) => {
        const width = el.width > 0 ? el.width : null;
        const style = {
          width: `${width}px`
        };
        const renderHeader = el.renderHeader;
        const renderHeaderData = renderHeader ? renderHeader(el) : null;
        if (isColspan) {
          if (
            el.code === 'projectManagerLeader' ||
            el.code === 'projectManager' ||
            el.code === 'languageLeader' ||
            el.code === 'worker'
          ) {
            if (el.code === 'projectManagerLeader') {
              return <ColspanHead key={index} />;
            } else {
              return null;
            }
          } else {
            if (renderHeader === 'string')
              return (
                <div className="head" key={index} style={style}>
                  <span>{el.value}</span>
                </div>
              );
            return (
              <div className="head" key={index} style={style}>
                {renderHeaderData}
              </div>
            );
          }
        }
        if (renderHeader === 'string')
          return (
            <div className="head" key={index} style={style}>
              <span>{el.value}</span>
            </div>
          );
        return (
          <div className="head" key={index} style={style}>
            {renderHeaderData}
          </div>
        );
      })}
    </DataTableColumnHeadStyle>
  );
};

/**
 * @description 테이블 row 컴포넌트
 * @param props
 */
const DataTableRowData = (props: {
  rowEnd: boolean;
  rowData:
    | Partial<IUser>
    | Partial<ILanguageSpec>
    | Partial<IProject>
    | Partial<ICompany>;
  onClick: () => void;
  columns: readonly {
    code: string;
    value: any;
    width: number;
    renderHeader?: any;
    renderRowData?: any;
  }[];
  gap?: number;
}) => {
  const { rowEnd, rowData, columns, onClick, gap } = props;
  const refRow = useRef<HTMLDivElement>(null);

  return (
    <DataTableRowDataStyle
      ref={refRow}
      onClick={onClick}
      rowEnd={rowEnd}
      gap={gap}
    >
      <div className="main">
        {columns.map((el, index) => {
          const data =
            rowData[
              el.code as keyof (
                | Partial<IUser>
                | Partial<ILanguageSpec>
                | Partial<IProject>
                | Partial<ICompany>
              )
            ];
          const renderRowData: any = el.renderRowData;
          const width = el.width > 0 ? el.width : null;
          const style = {
            width: `${width}px`
          };

          switch (renderRowData) {
            case 'string':
              return (
                <div className="rowDataBlock" key={index} style={style}>
                  {data}
                </div>
              );
            case 'boolean':
              return (
                <div className="rowDataBlock" key={index} style={style}>
                  {data ? 'Y' : 'N'}
                </div>
              );
            default:
              return (
                <div key={index} className="rowDataBlock" style={style}>
                  {renderRowData(data, rowData)}
                </div>
              );
          }
        })}
      </div>
    </DataTableRowDataStyle>
  );
};

/**
 * @description 테이블 컨테이너 컴포넌트
 * @param props
 */
const DataTableContainer = (props: {
  dataList: (
    | Partial<IUser>
    | Partial<ILanguageSpec>
    | Partial<IProject>
    | Partial<ICompany>
  )[];
  clickRowData?: (rowData: any) => void;
  noRowsMessage?: string;
  columns: readonly {
    code: string;
    value: any;
    width: number;
    renderHeader?: any;
    renderRowData?: any;
  }[];
  gap?: number;
}) => {
  const { dataList, columns, clickRowData, noRowsMessage } = props;
  return (
    <DataTableStyle>
      <div className="container">
        <DataTableColumnHead columns={columns} />
        {dataList && !!dataList.length ? (
          dataList.map((rowData, index) => {
            return (
              <DataTableRowData
                key={index}
                rowEnd={index === dataList.length - 1}
                rowData={rowData}
                columns={columns}
                onClick={() => {
                  if (clickRowData) clickRowData(rowData);
                }}
              />
            );
          })
        ) : (
          <NoMessageContainer>
            <span>{noRowsMessage}</span>
          </NoMessageContainer>
        )}
      </div>
    </DataTableStyle>
  );
};

/**
 * @description 테이블 컴포넌트
 * @param props
 */
const DataTable = (props: {
  dataList: (
    | Partial<IUser>
    | Partial<ILanguageSpec>
    | Partial<IProject>
    | Partial<ICompany>
    | Partial<ILanguageTask>
  )[];
  clickRowData?: (rowData: any) => void;
  noRowsMessage?: string;
  columns: readonly {
    code: string;
    value: any;
    width: number;
    renderHeader?: any;
    renderRowData?: any;
  }[];
  gap?: number;
}) => {
  const { columns, dataList, clickRowData, noRowsMessage, gap } = props;
  return (
    <DataTableContainer
      dataList={dataList}
      columns={columns}
      clickRowData={clickRowData}
      noRowsMessage={noRowsMessage}
      gap={gap}
    />
  );
};

export default DataTable;
