import { ReactElement, useState, cloneElement, MouseEvent } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { TPPopoverTheme } from './TPPopover.Theme';
import { Link } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';

/**
 * @description Popover
 * @param props
 */
export const TPPopoverMenu = (props: {
  children: ReactElement;
  popoverMenus: {
    clickMenu: () => void;
    label: string;
    to?: string;
    state?: {};
  }[];
  clickPopoverButton?: (e: MouseEvent<HTMLButtonElement>) => void;
}) => {
  const { children, popoverMenus, clickPopoverButton } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openPopover = (event: MouseEvent<HTMLButtonElement>) => {
    if (clickPopoverButton) clickPopoverButton(event);
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <ThemeProvider theme={TPPopoverTheme}>
      {cloneElement(children, { ...children.props, onClick: openPopover })}
      <Menu
        anchorEl={anchorEl}
        onClick={(e) => {
          e.stopPropagation();
        }}
        open={open}
        onClose={closePopover}
      >
        {popoverMenus.map((item, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                item.clickMenu();
                closePopover();
              }}
            >
              {item.to && item.state ? (
                <Link to={item?.to} state={{ ...item?.state }}>
                  {item.label}
                </Link>
              ) : (
                <span dangerouslySetInnerHTML={{ __html: item.label }} />
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </ThemeProvider>
  );
};
