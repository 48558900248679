import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';

import Layout from '@layouts/Layout';
import LoginPage from '@pages/login/Login.page';
import ModalLayout from '@layouts/ModalLayout';
import AuthPage from '@pages/Auth.page';

import ErrorBoundary from '@pages/errors/ErrorBoundary';
import AccountPage from '@pages/account/Account.page';
import LanguagePairPage from '@pages/languagePair/LanguagePair.page';
import ProjectPage from '@pages/project/Project.page';
import CompanyPage from '@pages/company/Company.page';
import AccountRegisterPage from '@pages/modal/AccountRegister.page';
import AccountModifyPage from '@pages/modal/AccountModify.page';
import LanguagePairModifyPage from '@pages/modal/LanguagePairModify.page';
import ProjectRegisterPage from '@pages/modal/ProjectRegister.page';
import ProjectModifyPage from '@pages/modal/ProjectModify.page';
import CompanyRegisterPage from '@pages/modal/CompanyRegister.page';
import CompanyModifyPage from '@pages/modal/CompanyModify.page';
import FileManagementPage from '@pages/fileManagement/FileManagement.page';
import FileUploadPage from '@pages/modal/FileUpload.page';
import StockRefine from '@pages/modal/StockRefine.page';
import LanguageTaskPage from '@src/pages/languageTask/LanguageTask.page';
import LanguageTaskUploadPage from '@src/pages/modal/LanguageTaskUpload.page';
import TaskCreatePage from '@src/pages/modal/TaskCreate.page';

/**
 * description: 라우터 객체를 생성하는 훅
 * @returns {object} routes
 */
export const useRouteObject = () => {
  const routeObject = [
    {
      path: '/',
      element: <AuthPage />,
      children: [
        { path: '/login', element: <LoginPage /> },
        {
          path: '/',
          element: <Layout />,
          children: [
            {
              path: 'account',
              element: <AccountPage />,
              children: [
                {
                  path: 'register',
                  element: (
                    <ModalLayout>
                      <AccountRegisterPage />
                    </ModalLayout>
                  )
                },
                {
                  path: 'modify',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':userId',
                      element: <AccountModifyPage />
                    }
                  ]
                }
              ]
            },
            {
              path: 'lang',
              element: <LanguagePairPage />,
              children: [
                {
                  path: 'modify',
                  element: (
                    <ModalLayout>
                      <LanguagePairModifyPage />
                    </ModalLayout>
                  )
                }
              ]
            },
            {
              path: 'project',
              element: <ProjectPage />,
              children: [
                {
                  path: 'register',
                  element: (
                    <ModalLayout>
                      <ProjectRegisterPage />
                    </ModalLayout>
                  )
                },
                {
                  path: 'modify',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':projectId',
                      element: <ProjectModifyPage />
                    }
                  ]
                }
              ]
            },
            {
              path: 'company',
              element: <CompanyPage />,
              children: [
                {
                  path: 'register',
                  element: (
                    <ModalLayout>
                      <CompanyRegisterPage />
                    </ModalLayout>
                  )
                },
                {
                  path: 'modify',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':companyId',
                      element: <CompanyModifyPage />
                    }
                  ]
                }
              ]
            },
            {
              path: 'languagetask',
              element: <LanguageTaskPage />,
              children: [
                {
                  path: 'upload',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':fileId',
                      element: <FileUploadPage />
                    }
                  ]
                },
                {
                  path: 'reUpload',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':fileId',
                      element: <FileUploadPage />
                    }
                  ]
                },
                {
                  path: 'create',
                  element: <ModalLayout />,
                  children: [
                    {
                      path: ':fileId',
                      element: <TaskCreatePage />
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      errorElement: <ErrorBoundary />
    }
  ];

  const routes = createBrowserRouter(routeObject);

  return { routes };
};
