import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useRouteObject } from '@src/hooks/router/useRouterObject';

if (process.env.NODE_ENV === 'production') {
  console.log = console.warn = console.error = () => {};
}

function App() {
  const queryClient = new QueryClient();
  const { routes } = useRouteObject();

  return (
    <QueryClientProvider client={queryClient}>
      <main>
        <RouterProvider router={routes} />
      </main>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
