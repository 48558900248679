import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@hooks/modal/useToast';
import { useAlert as alert } from '@hooks/modal/useAlert';

/**
 * /company
 * @description 고객사 삭제
 * @returns {deleteCompany: function}
 */
export const useDeleteCompany = () => {
  const queryClient = useQueryClient();
  const { openHandle: openAlert, closeHandle } = alert();
  const { openHandle } = useToast();
  //#region api call
  const getData = async (payload: any) => {
    const { companyId } = payload;
    return await api.delete(`/company/${companyId}`).then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { mutate: deleteCompany, variables } = useMutation({
    mutationFn: getData,
    onSuccess: () => {
      queryClient.invalidateQueries(['/company/list']);
      openHandle({
        text: `고객사 정보가 삭제 되었습니다.`,
        severity: 'success'
      });
    },
    onError: (error: any) => {
      if (error.errors && error.errors.length > 0) {
        openAlert({
          title: '고객사 삭제',
          text: `${variables?.companyName} 에 프로젝트가 등록되어있어<br/>삭제가 불가능합니다.`,
          cancelLabel: '',
          ok: () => closeHandle()
        });
      } else {
        openHandle({
          text: `고객사 삭제에 실패하였습니다.`,
          severity: 'error'
        });
      }
    }
  });
  //#endregion

  return {
    deleteCompany
  };
};
