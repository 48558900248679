import { ReactNode, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { TPButtonTheme } from './TPButton.Theme';

/**
 * @description Button Basic 컴포넌트
 * @param props
 */
export const TPButtonBasic = (props: {
  label?: string;
  to?: string;
  state?: {};
  variant?: 'text' | 'contained' | 'outlined' | undefined;
  size?: 'small' | 'medium' | 'large' | undefined;
  disabled?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  startIcon?: ReactNode | undefined;
  className?: string;
  onClick?: (e: MouseEvent) => void;
}) => {
  const {
    to,
    state,
    onClick,
    size,
    startIcon,
    label = '확인',
    loading = false
  } = props;
  const navigate = useNavigate();

  let indicatorSize = 16;
  switch (size) {
    case 'small':
      indicatorSize = 13;
      break;
    case 'medium':
      indicatorSize = 15;
      break;
    case 'large':
      indicatorSize = 17;
      break;
  }

  let indicator = <CircularProgress color="inherit" size={indicatorSize} />;

  return (
    <ThemeProvider theme={TPButtonTheme}>
      <LoadingButton
        {...props}
        loadingPosition={startIcon ? 'start' : undefined}
        loadingIndicator={indicator}
        loading={loading}
        onClick={(e: MouseEvent) => {
          if (to) {
            navigate(to, state);
            e.stopPropagation();
          }
          if (onClick) {
            onClick(e);
          }
        }}
      >
        {label}
      </LoadingButton>
    </ThemeProvider>
  );
};
