import React, { ReactNode } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { TPAvatarTheme } from './TPAvatar.Theme';
import { Badge, Avatar } from '@mui/material';
import AvatarBroken from '@images/avatar-broken.png';

/**
 * @description Avatar 컴포넌트
 * @param props
 * @param ref
 */
export const TPAvatarBasic = React.forwardRef(
  (
    props: {
      src?: string;
      alt?: string;
      size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | undefined;
      badge?: boolean;
      type?: 'group' | 'text' | string | undefined;
      children?: ReactNode | undefined;
      onClick?: (() => void) | string | undefined;
      sx?: Array<object> | object;
      bgcolor?: string;
    },
    ref:
      | ((instance: HTMLDivElement | null) => void)
      | React.RefObject<HTMLDivElement>
      | null
      | undefined
  ) => {
    const { size, badge, type, src, alt, children, onClick, bgcolor } = props;
    const propsOverride = {
      ...props,
      badge: null,
      type: null,
      bgcolor: null,
      onClick: typeof onClick === 'string' ? undefined : onClick
    };
    const avatartSrc = src ? src : AvatarBroken;
    return (
      <ThemeProvider theme={TPAvatarTheme}>
        {type === 'group' ? (
          <Avatar
            {...propsOverride}
            className={`${size ? size : ''} ${onClick ? 'cursor-pointer' : ''}`}
            src={avatartSrc}
            alt={alt}
            children={children}
            ref={ref}
            sx={bgcolor ? { bgcolor: bgcolor } : {}}
          />
        ) : type === 'text' ? (
          <Avatar
            {...propsOverride}
            className={`${size ? size : ''} ${onClick ? 'cursor-pointer' : ''}`}
            ref={ref}
            sx={bgcolor ? { bgcolor: bgcolor } : {}}
          >
            <span className="inner--text">{alt}</span>
          </Avatar>
        ) : (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            variant={badge ? 'dot' : undefined}
            className={size ? size : ''}
          >
            <Avatar
              {...propsOverride}
              className={`${size ? size : ''} ${
                onClick ? 'cursor-pointer' : ''
              }`}
              src={avatartSrc}
              alt={alt}
              ref={ref}
              sx={bgcolor ? { bgcolor: bgcolor } : {}}
            />
          </Badge>
        )}
      </ThemeProvider>
    );
  }
);
