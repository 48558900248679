//lib
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
//etc
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { IconRefresh } from '@resources/icon';
import { LanguagePairWrap } from './LanguagePair.style';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import { languagePairColumns } from '@pages/languagePair/LanguagePair.columns';
import DataTable from '@src/pages/component/DataTable';

//hooks
import { usePostLanguageSpecList } from '@hooks/languageSpec/postList';
import { TPSelectCheckManager } from '@units/select/TPSelect.checkPM';

/**
 * 언어관리 페이지
 * route : /account
 * @returns
 */
function LanguagePairPage() {
  const {
    filterOption,
    startLanguageList,
    changeStartLanguageList,
    destinationLanguageList,
    changeDestinationLanguageList,
    mainLanguageLeaderUserId,
    setMainLanguageLeaderUserId,
    subLanguageLeaderUserIdList,
    changeSubLanguageLeaderUserIdList,
    workUserIdList,
    changeWorkUserIdList,
    languageSpecList,
    setIsSort,
    isSort,
    clear,
    clearFlag
  } = usePostLanguageSpecList();

  const {
    startLanguageList: startLanguageOptions,
    destinationLanguageList: destinationLanguageOptions,
    mainLanguageLeaderList,
    subLanguageLeaderList,
    workerList
  } = filterOption;
  //#region store function

  return (
    <section css={LanguagePairWrap}>
      <Box mb={'26px'} display={'flex'}>
        <Box display={'flex'} flexDirection={'column'} flexGrow={1} ml={2}>
          <dl className="project--dl--info">
            <TPHeadlineBasic type="h2" textAlign="left">
              보라캣 언어 관리
            </TPHeadlineBasic>
          </dl>
        </Box>
      </Box>

      <Box mb={3} display={'flex'} justifyContent={'space-between'}>
        <Box display={'inline-flex'} gap={'8px'}>
          <TPSelectCheck
            label="출발어"
            useOptionLabel
            optionList={startLanguageOptions}
            selectOptions={startLanguageList}
            changeSelect={changeStartLanguageList}
          />
          <TPSelectCheck
            label="도착어"
            useOptionLabel
            optionList={destinationLanguageOptions}
            selectOptions={destinationLanguageList}
            changeSelect={changeDestinationLanguageList}
          />
          <TPSelectCheckManager
            label="메인언어리더"
            PMList={mainLanguageLeaderList}
            showAvatar={true}
            selectOptions={[mainLanguageLeaderUserId]}
            changeSelect={setMainLanguageLeaderUserId}
          />
          <TPSelectCheckManager
            label="서브언어리더"
            PMList={subLanguageLeaderList}
            showAvatar={true}
            selectOptions={subLanguageLeaderUserIdList}
            changeSelect={changeSubLanguageLeaderUserIdList}
          />
          <TPSelectCheckManager
            label="작업자"
            PMList={workerList}
            showAvatar={true}
            selectOptions={workUserIdList}
            changeSelect={changeWorkUserIdList}
          />
          {clearFlag ? null : (
            <button className="main--button--refresh" onClick={clear}>
              <IconRefresh />
              <span>초기화</span>
            </button>
          )}
        </Box>
      </Box>
      <Box>
        <DataTable
          columns={languagePairColumns(isSort, setIsSort)}
          dataList={languageSpecList}
          clickRowData={() => {}}
          noRowsMessage={'검색된 데이터가 없습니다.'}
        />
      </Box>
      <Outlet />
    </section>
  );
}

export default LanguagePairPage;
