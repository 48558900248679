import api from '@api/Api';
import { useMutation } from '@tanstack/react-query';

/**
 * /company/companyNameValid
 * @description 고객사명 중복 체크
 * @returns {
 *     companyNameValidData: any,
 *     companyNameValid: unknown,
 *     validCompanyNameError: function,
 *     validCompanyName: boolean
 *   }
 */
export const useGetCompanyNameValid = () => {
  //#region api call
  const getData = async (companyName: string) => {
    return await api
      .get(`/company/companyNameValid`, { companyName })
      .then((res) => res);
  };
  //#endregion

  //#region useQuery define
  const {
    data: companyNameValidData,
    error: validCompanyNameError,
    mutate: companyNameValid,
    isError: validCompanyName
  } = useMutation({ mutationFn: getData });
  //#endregion

  return {
    companyNameValidData,
    companyNameValid,
    validCompanyNameError,
    validCompanyName
  };
};
