import styled from '@emotion/styled';
import { Color } from '@src/common/styles/color';
import { IconSearch } from '@src/resources/icon';
import React from 'react';

/**
 * @description Search input new
 * @param props
 */
export const TPInputSearchNew = (props: {
  value: string;
  placeholder?: string;
  onChange: (e: any) => void;
  clickSearch: () => void;
  width?: number;
}) => {
  const { value, placeholder, onChange, clickSearch, width } = props;
  return (
    <Wrap>
      <Input
        value={value}
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') clickSearch();
        }}
        placeholder={placeholder}
        width={width}
      />
      <IconSearch
        className="button"
        width={24}
        height={24}
        onClick={clickSearch}
      />
    </Wrap>
  );
};

const Wrap = styled.div<
  Partial<{
    value: string;
    placeholder?: string;
    onChange: (e: any) => void;
    clickSearch: () => void;
    width?: number;
  }>
>`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 12px;
  .button {
    position: absolute;
    right: 20px;
    transition: 0.1s;
    :hover {
      cursor: pointer;
      opacity: 0.6;
    }
    :active {
      color: red;
      opacity: 0.95;
      fill: ${Color.main};
      stroke: ${Color.main};
    }
  }
`;

const Input = styled.input`
  width: ${({ width }) => (width ? `${width}px` : '168px')};

  height: 100%;
  border-radius: 12px;
  border: 1px solid #d5d3e4;
  padding: 0 40px 0 20px;
  color: #2c3558;
  transition: 0.1s;
  :hover {
    border-color: ${Color.main};
  }
  :focus {
    border-color: ${Color.main};
  }
  ::placeholder {
    color: #9a9cb5;
    font-size: 14px;
  }
`;
