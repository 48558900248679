import api from '@api/Api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useGetEmailValid } from '@hooks/users/getEmailValid';
import _ from 'lodash';
import { useGetSlackUserProfile } from '@hooks/users/getSlackUserProfile';
import { useToast } from '@hooks/modal/useToast';
import { useAlert } from '@hooks/modal/useAlert';
import { useNavigate } from 'react-router-dom';
import { IInitData } from '@hooks/users/getUsers';

/**
 * @description 계정 목록 조회 /users/list
 * @param {IInitData} initData
 * @returns {userId: string,
 *    setUserId: function,
 *    email: string,
 *    setEmail: function,
 *    name: string,
 *    setName: function,
 *    slackId: string,
 *    changeSlackId: function,
 *    validSlackId: function,
 *    roleList: string[],
 *    changeRoleList: function,
 *    avatarImgUrl: string,
 *    validInfo: IValidInfo,
 *    clickCreate: function,
 *    clickClose: function}
 */
export const usePutUsers = (initData?: IInitData) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openHandle: alertHandle } = useAlert();
  const { openHandle } = useToast();
  const { emailValid, isEmailValid, isEmailLoading } = useGetEmailValid();
  const {
    data: validSlackIdData,
    slackIdValid,
    slackIdError
  } = useGetSlackUserProfile();
  //#region state
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [slackId, setSlackId] = useState('');
  const [roleList, setRoleList] = useState<string[]>([]);
  const [avatarImgUrl, setAvatarImgUrl] = useState('');
  const [validInfo, setValidInfo] = useState<{
    email: '' | 'valid' | 'invalid' | 'invalid:regex';
    name: '' | 'valid' | 'invalid';
    slackId: '' | 'valid' | 'invalid' | 'invalid:regex';
    roleList: '' | 'valid' | 'invalid';
  }>({
    email: '',
    name: '',
    slackId: '',
    roleList: ''
  });
  const [checkOrigin, setCheckOrigin] = useState(false);
  //#endregion

  const validEmail = async () => {
    emailValid(email);
  };

  const changeSlackId = (slackId: string) => {
    const regex = /^[a-zA-Z0-9]*$/;
    if (!regex.test(slackId)) return;
    setSlackId(slackId);
  };

  const validSlackId = async () => {
    slackIdValid(slackId);
  };

  const changeRoleList = (role: string) => {
    if (_.includes(roleList, role)) {
      setRoleList(_.filter(roleList, (item) => item !== role));
    } else {
      setRoleList([...roleList, role]);
    }
  };

  const clickClose = () => {
    alertHandle({
      title: '수정 취소',
      text: '수정 중인 내용이 모두 삭제됩니다.',
      cancelLabel: '취소',
      ok: () => {
        navigate('/account');
      }
    });
  };

  const clickCreate = () => {
    alertHandle({
      title: '수정 확인',
      text: '내용을 수정하시겠습니까?',
      cancelLabel: '취소',
      ok: () => {
        createUser();
        navigate('/account', { replace: true });
      }
    });
  };

  //#region api call
  const getData = async () => {
    if (
      _.includes(validInfo, 'invalid') ||
      _.includes(validInfo, 'invalid:regex') ||
      _.includes(validInfo, '')
    )
      return;
    return await api
      .put('/users', { userId, name, slackId, roleList, avatarImgUrl })
      .then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { mutate: createUser } = useMutation({
    mutationFn: getData,
    onSuccess: () => {
      queryClient.invalidateQueries(['/users/list']);
      openHandle({ text: '계정이 수정되었습니다.', severity: 'success' });
    },
    onError: () => {
      openHandle({
        text: `계정 수정에 실패하였습니다.`,
        severity: 'error'
      });
    }
  });

  const putPasswordReset = async (status: { userId: string }) => {
    return await api
      .put('/users/passwordReset', status)
      .then((res) => res.data);
  };
  const { mutate: resetPassword } = useMutation({
    mutationFn: putPasswordReset,
    onSuccess: (data) => {
      alertHandle({
        title: `초기화 비밀번호`,
        text: `초기화된 비밀번호는<br/><span style="font-weight: bold; font-size: 20px; padding:0 5px; background:rgba(170,81,224,0.2) ;">${data}</span> 입니다.`,
        cancelLabel: '',
        ok: () => {
          navigate('/account', { replace: true });
          queryClient.invalidateQueries(['/users/list']);
          window.navigator.clipboard
            .writeText(data)
            .then(() => {
              openHandle({
                text: `비밀번호가 클립보드에 추가되었습니다.`,
                severity: 'success'
              });
            })
            .catch(() => {
              openHandle({
                text: `비밀번호를 클립보드에 추가에 실패하였습니다.`,
                severity: 'error'
              });
            });
        }
      });
    },
    onError: () => {
      openHandle({
        text: `비밀번호 초기화에 실패하였습니다.`,
        severity: 'error'
      });
    }
  });
  //#endregion

  useEffect(() => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const valid = !isEmailValid
      ? regex.test(email)
        ? 'valid'
        : 'invalid:regex'
      : regex.test(email)
      ? 'invalid'
      : 'invalid:regex';
    setValidInfo({
      ...validInfo,
      email: valid as 'valid' | 'invalid' | 'invalid:regex'
    });
    return () => {};
  }, [isEmailLoading]);

  useEffect(() => {
    setValidInfo({
      ...validInfo,
      name: !!name.length ? 'valid' : 'invalid'
    });
    return () => {};
  }, [name]);

  useEffect(() => {
    setValidInfo({
      ...validInfo,
      roleList: !!roleList.length ? 'valid' : 'invalid'
    });
    return () => {};
  }, [roleList]);

  useEffect(() => {
    if (slackIdError) {
      setValidInfo({
        ...validInfo,
        slackId: 'invalid'
      });
    } else {
      if (validSlackIdData?.ok) {
        setAvatarImgUrl(validSlackIdData?.profile?.image512);
        setValidInfo({
          ...validInfo,
          slackId: 'valid'
        });
      } else {
        setValidInfo({
          ...validInfo,
          slackId: 'invalid:regex'
        });
        setAvatarImgUrl('');
      }
    }
  }, [validSlackIdData, slackIdError]);

  useEffect(() => {
    if (initData) {
      const mutateData = { email, name, slackId, roleList, avatarImgUrl };
      let originData = {
        email: initData.email,
        name: initData.name,
        slackId: initData.slackId,
        roleList: [] as string[],
        avatarImgUrl: initData.avatarImgUrl
      };
      let originRoleList = [];
      if (initData.projectManagerLeader) originRoleList.push('PML');
      if (initData.projectManager) originRoleList.push('PM');
      if (initData.languageLeader) originRoleList.push('LL');
      if (initData.worker) originRoleList.push('TL');
      originData.roleList = originRoleList;

      if (JSON.stringify(mutateData) === JSON.stringify(originData)) {
        setCheckOrigin(true);
      } else {
        setCheckOrigin(false);
      }
    }
  }, [name, roleList, validSlackIdData, avatarImgUrl]);

  useEffect(() => {
    if (initData) {
      setUserId(initData.userId);
      setEmail(initData.email);
      setName(initData.name);
      setSlackId(initData.slackId);
      let roleList = [];
      if (initData.projectManagerLeader) roleList.push('PML');
      if (initData.projectManager) roleList.push('PM');
      if (initData.languageLeader) roleList.push('LL');
      if (initData.worker) roleList.push('TL');
      setRoleList(roleList);
      setAvatarImgUrl(initData.avatarImgUrl);
      setValidInfo({ ...validInfo, email: 'valid', name: 'valid' });
      slackIdValid(initData.slackId);
    }
    return () => {};
  }, [initData]);

  return {
    email,
    setEmail,
    validEmail,
    name,
    setName,
    slackId,
    changeSlackId,
    validSlackId,
    roleList,
    changeRoleList,
    avatarImgUrl,
    validInfo,
    checkOrigin,
    clickCreate,
    clickClose,
    resetPassword
  };
};
