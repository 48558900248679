//lib
import { Box } from '@mui/material';
import { useNavigate, Outlet } from 'react-router-dom';

//etc
import { TPHeadlineBasic } from '@units/character/TPHeadline.Basic';
import { IconRefresh } from '@resources/icon';
import { CompanyWrap } from './Company.style';
import { TPButtonBasic } from '@src/units/button/TPButton.Basic';
import { IconBtnRocket } from '@resources/icon';
import { TPSelectCheck } from '@src/units/select/TPSelect.check';
import { companyColumns } from '@src/pages/company/Company.columns';
import { TPInputSearchNew } from '@src/units/input/TPInput.searchNew';
import DataTable from '@src/pages/component/DataTable';

//hooks
import { useGetCompanyList } from '@hooks/company/getList';

/**
 * 계정관리 페이지
 * route : /account
 * @returns
 */
function CompanyPage() {
  const navigate = useNavigate();

  const {
    changeCompanyName,
    setChangeCompanyName,
    updateCompanyName,
    iconImgUrlStatus,
    setIconImgUrlStatus,
    setIsSort,
    isSort,
    clear,
    clearFlag,
    companyList
  } = useGetCompanyList();
  //#region store function

  return (
    <section css={CompanyWrap}>
      <Box mb={'26px'} display={'flex'}>
        <Box display={'flex'} flexDirection={'column'} flexGrow={1} ml={2}>
          <dl className="project--dl--info">
            <TPHeadlineBasic type="h2" textAlign="left">
              보라캣 고객사 관리
            </TPHeadlineBasic>
          </dl>
        </Box>
      </Box>

      <Box mb={3} display={'flex'} justifyContent={'space-between'}>
        <Box display={'inline-flex'} gap={'8px'}>
          <TPInputSearchNew
            value={changeCompanyName}
            placeholder="고객사명 검색"
            onChange={(e) => setChangeCompanyName(e.target.value)}
            clickSearch={updateCompanyName}
          />
          <TPSelectCheck
            label="CI"
            useOptionLabel
            optionList={[
              { code: '', label: '전체' },
              { code: 'true', label: '등록' },
              { code: 'false', label: '미등록' }
            ]}
            selectOptions={[iconImgUrlStatus]}
            changeSelect={setIconImgUrlStatus}
          />

          {clearFlag ? null : (
            <button className="main--button--refresh" onClick={clear}>
              <IconRefresh />
              <span>초기화</span>
            </button>
          )}
        </Box>

        <TPButtonBasic
          className=""
          label="고객사 등록"
          onClick={() => {
            navigate('/company/register');
          }}
          size="medium"
          startIcon={<IconBtnRocket />}
          variant="contained"
        />
      </Box>

      <Box>
        <DataTable
          columns={companyColumns(isSort, setIsSort)}
          dataList={companyList}
          clickRowData={() => {}}
          noRowsMessage={'등록된 고객사가 없습니다.'}
        />
      </Box>

      <Outlet />
    </section>
  );
}

export default CompanyPage;
