import { TPInputCheckBoxWrap } from './TPInput.theme';
import { Box, Checkbox } from '@mui/material';
import { IconChecked, IconUnChecked } from '@resources/icon';
import { TPAvatarBasic } from '../avatar/TPAvatar.Basic';

/**
 * @description Input CheckBox 컴포넌트
 * @param props
 */
export const TPInputCheckBox = (props: {
  option: string;
  checked?: boolean;
  optionKey: string;
  selectOptions: (string | number)[];
  avatarUrl?: string;
  showAvatar?: boolean;
  disabled?: boolean;
  onClick: (state: string) => void;
}) => {
  const {
    option,
    optionKey,
    selectOptions,
    avatarUrl,
    onClick,
    disabled,
    showAvatar
  } = props;

  const icon = <IconChecked width={24} height={24} />;
  const unCheckIcon = <IconUnChecked width={24} height={24} />;

  const checked = selectOptions.includes(optionKey);

  return (
    <TPInputCheckBoxWrap
      className={disabled ? 'disabled' : ''}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) onClick(optionKey);
      }}
    >
      <Checkbox
        checked={checked}
        value={option}
        icon={unCheckIcon}
        checkedIcon={icon}
        disabled={disabled}
      />

      {showAvatar && option !== '전체' ? (
        <>
          <TPAvatarBasic
            sx={{ marginRight: '12px' }}
            alt={option}
            size="xsmall"
            src={avatarUrl}
          />
          <Box sx={{ width: '8px' }}></Box>
        </>
      ) : null}

      <Box>{option}</Box>
    </TPInputCheckBoxWrap>
  );
};

/**
 * @description Input CheckBox 컴포넌트
 * @param props
 */
export const TPInputCheckSingleBox = (props: {
  option: string;
  checked: boolean;
  avatarUrl?: string;
  showAvatar?: boolean;
  disabled?: boolean;
  onClick: (state: any) => void;
}) => {
  const { option, checked, avatarUrl, onClick, disabled, showAvatar } = props;

  const icon = <IconChecked width={24} height={24} />;
  const unCheckIcon = <IconUnChecked width={24} height={24} />;

  return (
    <TPInputCheckBoxWrap
      className={disabled ? 'disabled' : ''}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) onClick(!checked);
      }}
    >
      <Checkbox
        checked={checked}
        value={option}
        icon={unCheckIcon}
        checkedIcon={icon}
        disabled={disabled}
      />

      {showAvatar && option !== '전체' ? (
        <>
          <TPAvatarBasic
            sx={{ marginRight: '12px' }}
            alt={option}
            size="xsmall"
            src={avatarUrl}
          />
          <Box sx={{ width: '8px' }}></Box>
        </>
      ) : null}

      <Box>{option}</Box>
    </TPInputCheckBoxWrap>
  );
};
