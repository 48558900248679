import api from '@api/Api';
import { useQuery } from '@tanstack/react-query';
import { projectKeys } from '../queryKeyFactory';

/**
 * /projectManage/companyList
 * @description 언어페어 리더, 작업자 전체 조회
 * @returns { companyList: { companyId: string, companyName: string }[] }
 */
export const useGetProjectCompanyList = () => {
  //#region api call
  const getData = async (): Promise<{
    companyList: {
      companyId: string;
      companyName: string;
    }[];
  }> => {
    return await api.get('/projectManage/companyList').then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { data: companyList } = useQuery(
    [...projectKeys.companyList],
    getData,
    {
      initialData: () => ({
        companyList: []
      })
    }
  );
  //#endregion

  return {
    companyList
  };
};
