import styled from '@emotion/styled';

/**
 * @description DataTable column head style
 * @param {number} gap - column 사이 간격
 */
export const DataTableColumnHeadStyle = styled.div<{ gap?: number }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => (gap ? `${gap}px` : '16px')};
  height: 56px;
  border-bottom: 1px solid #eeedf4;

  .head {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #2c3558;
    &.colspan {
      position: relative;
      margin-top: -15px;
      > .head--bottom {
        display: flex;
        gap: 16px;
      }
    }
  }
  .head--up--down {
    width: 18px;
    height: 100%;
  }
  .head--color {
    background: #faf6fe;
  }
`;

/**
 * @description DataTable row data style
 * @param {boolean} rowEnd - 해당 row data가 마지막인지 여부
 * @param {number} gap - column 사이 간격
 */
export const DataTableRowDataStyle = styled.div<{
  rowEnd: boolean;
  gap?: number;
}>`
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  text-align: center;
  .main {
    position: relative;
    display: flex;
    align-items: center;
    gap: ${({ gap }) => (gap ? `${gap}px` : '16px')};
    padding: 16px 0;
    border-bottom: 1px solid #eeedf4;

    ::before {
      position: absolute;
      content: '';
      height: 100%;
      width: 120px;
      left: 20px;
      bottom: 0;
      z-index: 10;
    }
    .rowDataBlock {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .rowDataBlock--up--down {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      left: 10px;
    }
    :hover {
      cursor: pointer;
      background: #faf6fe;
    }
  }
  .sub {
    > .sub--rowData {
      position: relative;
      background-color: #fafaff;
      display: flex;
      align-items: center;
      gap: ${({ gap }) => (gap ? `${gap}px` : '16px')};
      padding: 16px 0;
      border-bottom: 1px solid #eeedf4;
      > .status--key {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: calc(18px + 17.9px + 32px + 58px);
        width: 323.4px;

        > .line {
          position: absolute;
          left: calc(18px + 17.9px + 32px);
          bottom: 50%;
          width: 58px;
          border-bottom: 1px solid #d5d3e4;
        }
        > .line.endSubRow {
          border-left: 1px solid #d5d3e4;
          border-radius: 0 0 0 8px;
        }
        > .badge {
          background-color: blue;
        }
        > .key {
          flex-grow: 0.9;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: 1px solid #d5d3e4;
          border-radius: 6px;
          height: 30px;
          &.not--exist {
            color: #e6311a;
          }
        }
      }
      .rowDataBlock {
      }
      &.none--bottom--line {
        border-bottom: none;
      }
    }
  }
`;

export const DataTableStyle = styled.div`
  width: 1768px;
  height: 100%;
  margin-top: 20px;
  border-radius: 16px;
  padding: 16px 13px;
  background: #ffffff;
  box-shadow: 0 1px 32px 4px #f0edf6;
  .container {
    width: 100%;
    height: 100%;
  }
`;

export const NoMessageContainer = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #9a9cb5;
    font-size: 16px;
  }
`;
