import { useQuery, useQueryClient } from '@tanstack/react-query';

const useSetGlobalState = (key: any) => {
  const queryClient = useQueryClient();
  return (state: any) => queryClient.setQueryData(key, state);
};

const useGlobalValue = (key: string[], initialData?: any) =>
  useQuery(key, {
    initialData,
    staleTime: Infinity,
    cacheTime: Infinity
  }).data;

export { useSetGlobalState, useGlobalValue };
