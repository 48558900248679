import Config from '@common/Config';
import axios from 'axios';
import Logger from './Logger';

const interceptor = axios.create({
  baseURL: `${Config.apiBaseUrl}admin`,
  timeout: Config.apiTimeOut,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  }
});
interceptor.defaults.headers.post['Content-Type'] = 'application/json';

interceptor.interceptors.request.use(
  async (config) => {
    config.headers = config.headers ?? {};

    if (localStorage.getItem('accessToken')) {
      const accessExp = localStorage.getItem('accessTokenExpiresIn');
      const nowDate = new Date().getTime();

      if (Number(accessExp) < nowDate) {
        const refreshToken = localStorage.getItem('refreshToken') || '';
        const isRefresh = sessionStorage.getItem('isRefresh');
        if (!isRefresh) {
          sessionStorage.setItem('isRefresh', 'true');
          await axios
            .post(`${Config.apiBaseUrl}/auth/refresh`, {
              refreshToken,
              loginSite: 'ADMIN'
            })
            .then((res) => {
              const data = res.data;
              localStorage.setItem('accessToken', data.accessToken);
              localStorage.setItem('refreshToken', data.refreshToken);
              localStorage.setItem(
                'accessTokenExpiresIn',
                data.accessTokenExpiresIn
              );
              sessionStorage.removeItem('isRefresh');
            })
            .catch((error) => {
              localStorage.setItem('accessToken', error.response.data.status);
              sessionStorage.removeItem('isRefresh');
            });
        }
      }

      (config.headers || {}).Authorization =
        `bearer ${localStorage.getItem('accessToken') as string}` || '';
    }

    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
interceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let errorResponse = error.response || {};
    let errorData = errorResponse.data || {};
    let status = errorResponse.status || 500;

    // 클라이언트 오류
    if (status >= 400 && status < 500) {
      if (status === 401) {
        // 인증 오류시 실행될 문장
      } else if (status === 403) {
        // 권한 오류시 실행될 문장
      } else {
        // 그 외 오류
      }
    } else if (status >= 500) {
      // 서버 오류
      Logger.error(errorResponse);
    }

    return Promise.reject(errorData);
  }
);

export default interceptor;
