import { ReactElement } from 'react';
import { TPHeadline2Theme, TPHeadline3Theme } from './TPHeadline.Theme';

/**
 * @description Headline 컴포넌트
 * @param props
 */
export const TPHeadlineBasic = (props: {
  display?: 'block' | 'inline-block' | 'inline' | 'flex' | 'inline-flex';
  type?: 'h2' | 'h3' | undefined;
  decoration?: boolean;
  children: ReactElement | ReactElement[] | string;
  textAlign?: 'left' | 'center' | 'right';
}) => {
  const {
    display = 'inline-block',
    type = 'h2',
    textAlign = 'center',
    children,
    decoration
  } = props;
  let headline =
    type === 'h3' ? (
      <TPHeadline3Theme
        className={decoration ? 'decoration' : ''}
        style={{ display: display, textAlign: textAlign }}
      >
        <span>{children}</span>
      </TPHeadline3Theme>
    ) : (
      <TPHeadline2Theme
        className={decoration ? 'decoration' : ''}
        style={{ display: display, textAlign: textAlign }}
      >
        <span>{children}</span>
      </TPHeadline2Theme>
    );
  return headline;
};
