import api from '@api/Api';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { projectKeys } from '@hooks/queryKeyFactory';
import { IMember, IProject, IProjectList } from '@common/interfaces';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useToast } from '@hooks/modal/useToast';
import { usePageFunc } from '../utils';

interface IFilters {
  searchType?: string;
  changeSearchType?: string;
  keyword?: string | '';
  projectStatus?: string[];
  projectManagerUserIdList?: string[];
  contractType?: string;
  exposureStatus?: string;
  dateType?: string;
  dateFilter?: {
    startDateTime: Date | '';
    endDateTime: Date | '';
  };
}

const initialFilters: IFilters = {
  searchType: '',
  changeSearchType: '',
  keyword: '',
  projectStatus: [''],
  projectManagerUserIdList: [''],
  contractType: '',
  exposureStatus: '',
  dateType: '',
  dateFilter: {
    startDateTime: '',
    endDateTime: ''
  }
};
/**
 * @description 프로젝트 목록 조회 /projectManage/allList
 * @returns { filterOption: IFilterOptionList,
 *     changeSearchType: string[],
 *     changeSearchTypes: (searchTypeValue: string) => void,
 *     changeKeyword: string,
 *     setChangeKeyword: (keywordValue: string) => void,
 *     updateKeyword: () => void,
 *     projectStatus: string[],
 *     changeProjectStatus: (projectStatusValue: string) => void,
 *     projectManagerUserIdList: string[],
 *     changeProjectManagerUserIdList: (projectManagerUserIdListValue: string) => void,
 *     contractType: string,
 *     setContractType: (contractTypeValue: string) => void,
 *     exposureStatus: string,
 *     setExposureStatus: (exposureStatusValue: string) => void,
 *     dateType: string,
 *     setDateType: (dateTypeValue: string) => void,
 *     dateFilter: { startDateTime: string; endDateTime: string },
 *     setDateFilter: (dateFilterValue: { startDateTime: string; endDateTime: string }) => void,
 *     projectList: IProjectList,
 *     setIsSort: (isSortValue: boolean) => void,
 *     isSort: boolean,
 *     clear: () => void,
 *     clearFlag: boolean }
 */
export const usePostProjectList = () => {
  const { openHandle } = useToast();
  const { getDateTime } = usePageFunc();

  //#region state
  const [filterOption, setFilterOption] = useState<{
    mainProjectManagerList: IMember[];
    subProjectManagerList: IMember[];
    totalProjectManagerList: IMember[];
  }>({
    mainProjectManagerList: [],
    subProjectManagerList: [],
    totalProjectManagerList: []
  });
  const [searchType, setSearchType] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [changeSearchType, setChangeSearchType] = useState<string>('');
  const [changeKeyword, setChangeKeyword] = useState<string>('');
  const [projectStatus, setProjectStatus] = useState<string[]>(['']);
  const [projectManagerUserIdList, setProjectManagerUserIdList] = useState<
    string[]
  >(['']);
  const [contractType, setContractType] = useState<string>('');
  const [exposureStatus, setExposureStatus] = useState<string>('');
  const [dateType, setDateType] = useState<string>('');
  const [dateFilter, setDateFilter] = useState<{
    startDateTime: string;
    endDateTime: string;
  }>({
    startDateTime: '',
    endDateTime: ''
  });
  const [clearFlag, setClearFlag] = useState(false);
  const [isSort, setIsSort] = useState<{ [key: string]: any }>({
    code: '',
    isAsc: false
  });
  //#endregion

  const updateKeyword = () => {
    if (changeKeyword) {
      setSearchType(changeSearchType);
      setKeyword(changeKeyword);
    } else {
      openHandle({ text: '검색어를 입력해주세요.', severity: 'error' });
    }
  };

  const changeProjectStatus = (projectStatusValue: string) => {
    let projectStatusList: string[] = [];
    if (projectStatusValue === '') {
      projectStatusList = [''];
    } else {
      if (projectStatus.includes(projectStatusValue)) {
        projectStatusList = _.filter(
          projectStatus,
          (item) => item !== projectStatusValue
        );
      } else {
        projectStatusList = [...projectStatus, projectStatusValue];
      }
      projectStatusList = _.filter(projectStatusList, (item) => item !== '');
    }
    if (projectStatusList.length === 0) projectStatusList = [''];
    setProjectStatus(projectStatusList);
  };

  const changeProjectManagerUserIdList = (
    projectManagerUserIdListValue: string
  ) => {
    let projectManagerUserIdListList: string[] = [];
    if (projectManagerUserIdListValue === '') {
      projectManagerUserIdListList = [''];
    } else {
      if (projectManagerUserIdList.includes(projectManagerUserIdListValue)) {
        projectManagerUserIdListList = _.filter(
          projectManagerUserIdList,
          (item) => item !== projectManagerUserIdListValue
        );
      } else {
        projectManagerUserIdListList = [
          ...projectManagerUserIdList,
          projectManagerUserIdListValue
        ];
      }
      projectManagerUserIdListList = _.filter(
        projectManagerUserIdListList,
        (item) => item !== ''
      );
    }
    if (projectManagerUserIdListList.length === 0)
      projectManagerUserIdListList = [''];
    setProjectManagerUserIdList(projectManagerUserIdListList);
  };

  const clear = () => {
    const {
      searchType,
      keyword,
      projectStatus,
      projectManagerUserIdList,
      contractType,
      exposureStatus,
      dateType
    } = initialFilters;
    setSearchType(searchType as string);
    setKeyword(keyword as string);
    setChangeSearchType(searchType as string);
    setChangeKeyword(keyword as string);
    setProjectStatus(projectStatus as string[]);
    setProjectManagerUserIdList(projectManagerUserIdList as string[]);
    setContractType(contractType as string);
    setExposureStatus(exposureStatus as string);
    setDateType(dateType as string);
    setDateFilter({
      startDateTime: '',
      endDateTime: ''
    });
  };

  //#region api call
  const getFilterData = async () => {
    return await api
      .get('/projectManage/projectManagerList')
      .then((res) => res.data);
  };
  const getData = async ({
    queryKey
  }: QueryFunctionContext<
    ReturnType<(typeof projectKeys)['allList']>
  >): Promise<IProjectList> => {
    const [, filter] = queryKey;
    let payload: IFilters = {};
    if (filter.searchType) payload.searchType = filter.searchType;
    if (filter.keyword) payload.keyword = filter.keyword;
    if (!_.includes(filter.projectStatus, ''))
      payload.projectStatus = filter.projectStatus;
    if (!_.includes(filter.projectManagerUserIdList, ''))
      payload.projectManagerUserIdList = filter.projectManagerUserIdList;
    if (filter.contractType) payload.contractType = filter.contractType;
    if (filter.exposureStatus) payload.exposureStatus = filter.exposureStatus;
    if (filter.dateType) payload.dateType = filter.dateType;
    if (filter.dateFilter) {
      const { startDateTime, endDateTime } = filter.dateFilter;
      if (startDateTime && endDateTime)
        payload = {
          ...payload,
          ...{
            startDateTime: getDateTime({
              date: new Date(startDateTime),
              filter: 'start'
            }),
            endDateTime: getDateTime({
              date: new Date(endDateTime),
              filter: 'end'
            })
          }
        };
    }

    return await api
      .post('/projectManage/allList', payload)
      .then((res) => res.data);
  };
  //#endregion

  //#region useQuery define
  const { data: projectManagerList } = useQuery(
    [...projectKeys.managerList],
    getFilterData
  );
  const {
    data: { projectList }
  } = useQuery(
    [
      ...projectKeys.allList({
        searchType,
        keyword,
        projectStatus,
        projectManagerUserIdList,
        contractType,
        exposureStatus,
        dateType,
        dateFilter
      })
    ],
    getData,
    {
      initialData: () => ({
        projectList: []
      }),
      select: (data) => ({
        projectList: isSort.code.length
          ? ([
              ..._.orderBy(
                data.projectList,
                [isSort.code],
                [isSort.isAsc ? 'asc' : 'desc']
              )
            ] as IProject[])
          : data.projectList
      })
    }
  );
  //#endregion

  useEffect(() => {
    if (projectManagerList) {
      const { mainProjectManagerList, subProjectManagerList } =
        projectManagerList;
      setFilterOption({
        mainProjectManagerList: [
          { userId: '', name: '전체', avatarImgUrl: '' },
          ..._.map(mainProjectManagerList, (item): IMember => {
            return {
              userId: item.userId,
              name: item.name,
              avatarImgUrl: item.avatarImgUrl
            };
          })
        ],
        subProjectManagerList: [
          { userId: '', name: '전체', avatarImgUrl: '' },
          ..._.map(subProjectManagerList, (item): IMember => {
            return {
              userId: item.userId,
              name: item.name,
              avatarImgUrl: item.avatarImgUrl
            };
          })
        ],
        totalProjectManagerList: [
          { userId: '', name: '전체', avatarImgUrl: '' },
          ..._.map(
            _.unionBy(mainProjectManagerList, subProjectManagerList, 'userId'),
            (item: any): IMember => {
              return {
                userId: item.userId,
                name: item.name,
                avatarImgUrl: item.avatarImgUrl
              };
            }
          )
        ]
      });
    }
  }, [projectManagerList]);

  useEffect(() => {
    const filter = {
      searchType,
      changeSearchType,
      keyword,
      projectStatus,
      projectManagerUserIdList,
      contractType,
      exposureStatus,
      dateType,
      dateFilter
    };

    setClearFlag(JSON.stringify(initialFilters) === JSON.stringify(filter));

    console.debug(JSON.stringify(initialFilters), JSON.stringify(filter));
    return () => {};
  }, [
    searchType,
    changeSearchType,
    keyword,
    projectStatus,
    projectManagerUserIdList,
    contractType,
    exposureStatus,
    dateType,
    dateFilter
  ]);

  return {
    filterOption,
    changeSearchType,
    setChangeSearchType,
    changeKeyword,
    setChangeKeyword,
    updateKeyword,
    projectStatus,
    changeProjectStatus,
    projectManagerUserIdList,
    changeProjectManagerUserIdList,
    contractType,
    setContractType,
    exposureStatus,
    setExposureStatus,
    dateType,
    setDateType,
    dateFilter,
    setDateFilter,
    projectList,
    setIsSort,
    isSort,
    clear,
    clearFlag
  };
};
